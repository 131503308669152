import React from "react";
import { Content } from "adminlte-2-react";
import {
  // useMutation,
  useQuery,
  // useQueryClient
} from "react-query";
import { confirm } from "components/ConfirmDialog";
import KompetensiBidang from "./kompetensiBidang";
import Anak from "./anak";
import Beasiswa from "./beasiswa";
import Buku from "./buku";
import Diklat from "./diklat";
import KaryaTulis from "./karyaTulis";
import Tunjangan from "./tunjangan";
import TugasTambahan from "./tugasTambahan";
import RiwayatSertifikasi from "./riwayatSertifikasi";
import Kesejahteraan from "./kesejahteraan";
import Penghargaan from "./penghargaan";
import NilaiTest from "./nilaiTest";
import RiwayatGaji from "./riwayatGaji";
import RiwayatJabatan from "./riwayatJabatan";
import RiwayatKepangkatan from "./riwayatKepangkatan";
import RiwayatPendidikan from "./riwayatPendidikan";
import RiwayatJabatanFunctional from "./riwayatJabatanFunctional";
import RiwayatKarirGuru from "./riwayatKarirGuru";
import { useForm } from "react-hook-form";
import { submit } from "components/utils/proxy";

const DetailPTK = (props) => {
  const { history, match } = props;
  const user = JSON.parse(localStorage.getItem('user'));
  const { params } = match;
  const { slug } = params;
  let id = parseInt(slug.split("-").pop());
  const [componentDidUpdate, setComponentDidUpdate] = React.useState(false);
  let { handleSubmit, errors, register } = useForm();
  let {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm();
  let {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
  } = useForm();
  let {
    register: register4,
    errors: errors4,
    handleSubmit: handleSubmit4,
  } = useForm();
  let {
    register: register5,
    errors: errors5,
    handleSubmit: handleSubmit5,
  } = useForm();
  let {
    register: register6,
    errors: errors6,
    handleSubmit: handleSubmit6,
  } = useForm();
  let {
    register: register7,
    errors: errors7,
    handleSubmit: handleSubmit7,
  } = useForm();
  let {
    register: register8,
    errors: errors8,
    handleSubmit: handleSubmit8,
  } = useForm();
  let {
    register: register9,
    errors: errors9,
    handleSubmit: handleSubmit9,
  } = useForm();
  let {
    register: register10,
    errors: errors10,
    handleSubmit: handleSubmit10,
  } = useForm();
  let {
    register: register11,
    errors: errors11,
    handleSubmit: handleSubmit11,
  } = useForm();
  let {
    register: register12,
    errors: errors12,
    handleSubmit: handleSubmit12,
  } = useForm();
  let {
    register: register13,
    errors: errors13,
    handleSubmit: handleSubmit13,
  } = useForm();
  let {
    register: register14,
    errors: errors14,
    handleSubmit: handleSubmit14,
  } = useForm();
  let {
    register: register15,
    errors: errors15,
    handleSubmit: handleSubmit15,
  } = useForm();
  let {
    register: register16,
    errors: errors16,
    handleSubmit: handleSubmit16,
  } = useForm();
  let {
    register: register17,
    errors: errors17,
    handleSubmit: handleSubmit17,
  } = useForm();
  let {
    register: register18,
    errors: errors18,
    handleSubmit: handleSubmit18,
  } = useForm();

  const { data } = useQuery(
    ["detail-ptk", { slug, componentDidUpdate }],
    async () => {
      let res = await submit("POST", `api/page/directoryPTK/getById`, {
        id: id,
      });
      return res.data[0];
    }
  );
  const doCreateKompetensiBidang = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createKompetensiBidang?id_ptk=${id}`,
      data
    );
    setComponentDidUpdate(res.id);
  };

  const doCreateAnak = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createAnak?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateBeasiswa = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createBeasiswa?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateBuku = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createBuku?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateDiklat = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createDiklat?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateKarya = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createKarya?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateKesejahteraan = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createKesejahteraan?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateTunjangan = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createTunjangan?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateTugas = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createTugas?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreatePenghargaan = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createPenghargaan?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateNilaiTes = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createNilaiTes?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateGaji = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/cCreateGaji?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateJabatanStruktural = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createJabatanStruktural?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateRiwayatKepangkatan = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createRiwayatKepangkatan?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateRiwayatPendidikan = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createRiwayatPendidikan?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateRiwayatSertifikasi = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createRiwayatSertifikasi?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateJabatanFungsional = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createJabatanFungsional?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doCreateKarirGuru = async (data, event) => {
    event.preventDefault();
    let res = await submit(
      "POST",
      `api/page/directoryPTK/createKarirGuru?id_ptk=${id}`,
      data
    );
    if (res.success_message) {
      setComponentDidUpdate(!componentDidUpdate);
    }
  };

  const doDeleteKompetensiBidang = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_kompetensi: data.id_kompetensi,
      };
      let res = await submit(
        "POST",
        `api/page/directoryPTK/deleteKompetensiBidang`,
        params
      );
      if (res.success_message) {
        setComponentDidUpdate(!componentDidUpdate);
      }
    });
  };

  const doDeleteAnak = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_anak: data.id_anak,
      };
      let res = await submit(
        "POST",
        `api/page/directoryPTK/deleteAnak`,
        params
      );
      if (res.success_message) {
        setComponentDidUpdate(!componentDidUpdate);
      }
    });
  };

  const doDeleteBeasiswa = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_beasiswa: data.id_beasiswa,
      };
      let res = await submit(
        "POST",
        `api/page/directoryPTK/deleteBeasiswa`,
        params
      );
      if (res.success_message) {
        setComponentDidUpdate(!componentDidUpdate);
      }
    });
  };

  const doDeleteBuku = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_buku: data.id_buku,
      };
      let res = await submit(
        "POST",
        `api/page/directoryPTK/deleteBuku`,
        params
      );
      if (res.success_message) {
        setComponentDidUpdate(!componentDidUpdate);
      }
    });
  };

  const doDeleteDiklat = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_diklat: data.id_diklat,
      };
      let res = await submit(
        "POST",
        `api/page/directoryPTK/deleteDiklat`,
        params
      );
      if (res.success_message) {
        setComponentDidUpdate(!componentDidUpdate);
      }
    });
  };

  const doDeleteKaryaTulis = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_karya: data.id_karya,
      };
      let res = await submit(
        "POST",
        `api/page/directoryPTK/deleteKaryaTulis`,
        params
      );
      if (res.success_message) {
        setComponentDidUpdate(!componentDidUpdate);
      }
    });
  };

  const doDeleteKesejahteraan = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_kesejahteraan: data.id_kesejahteraan,
      };
      let res = await submit(
        "POST",
        `api/page/directoryPTK/deleteKesejahteraan`,
        params
      );
      if (res.success_message) {
        setComponentDidUpdate(!componentDidUpdate);
      }
    });
  };

  return (
    <Content
      title="Detail PTK"
      subTitle="SEKOLAH PELOPOR"
      homeRoute={`${process.env.PUBLIC_URL}/dashboard/directory-ptk`}
      browserTitle="Direktory PTK"
    >
      <section className="content invoice">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="page-header" style={{ textAlign: "center" }}>
              <small>
                <strong>FORMULIR PENDIDIK DAN TENAGA KEPENDIDIKAN</strong>
              </small>
              <small>18 Juli 2020</small>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>IDENTITAS SEKOLAH</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>NAMA SEKOLAH</th>
                  <td style={{ width: "50%" }}>:{user?.unit?.nama_unit}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>ALAMAT SEKOLAH</th>
                  <td style={{ width: "50%" }}>: {user?.unit?.alamat}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>NSS</th>
                  <td style={{ width: "50%" }}>: 104090204088</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NPSN</th>
                  <td style={{ width: "50%" }}>: 10496574</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 1px" }}
            >
              <h4 style={{ marginBottom: 0 }}>
                IDENTITAS PENDIDIK DAN TENAGA KEPENDIDIKAN
              </h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>NAMA LENGKAP</th>
                  <td style={{ width: "50%" }}>: {data?.nama}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NIK</th>
                  <td style={{ width: "50%" }}>: {data?.nik}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>JENIS KELAMIN</th>
                  <td style={{ width: "50%" }}>: {data?.jenis_kelamin}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>TEMPAT LAHIR</th>
                  <td style={{ width: "50%" }}>: {data?.tempat_lahir}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>TANGGAL LAHIR</th>
                  <td style={{ width: "50%" }}>:{data?.tanggal_lahir}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NAMA IBU KANDUNG</th>
                  <td style={{ width: "50%" }}>: {data?.nama_ibu}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5p 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>DATA PRIBADI</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>ALAMAT</th>
                  <td style={{ width: "50%" }}>: {data?.alamat}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>DUSUN</th>
                  <td style={{ width: "50%" }}>
                    : RT : {data?.rt} / RW : {data?.rw}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>KELUARAHAN / DESA</th>
                  <td style={{ width: "50%" }}>: {data?.kelurahan}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>KODE POS</th>
                  <td style={{ width: "50%" }}>: {data?.kode_pos}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>KECAMATAN</th>
                  <td style={{ width: "50%" }}>: {data?.kecamatan}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>KABUPATEN</th>
                  <td style={{ width: "50%" }}>: {data?.kabupaten}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>PROVINSI</th>
                  <td style={{ width: "50%" }}>: {data?.provinsi}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>LINTANG , BUJUR</th>
                  <td style={{ width: "50%" }}>
                    : {data?.lintang},{data?.bujur}{" "}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>AGAMA</th>
                  <td style={{ width: "50%" }}>: {data?.agama}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NPWP</th>
                  <td style={{ width: "50%" }}>: {data?.npwp}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NAMA WAJIB PAJAK</th>
                  <td style={{ width: "50%" }}>: {data?.nama_npwp}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>KEWARGANEGARAAN</th>
                  <td style={{ width: "50%" }}>: {data?.kewarganegaraan}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>STATUS KAWIN</th>
                  <td style={{ width: "50%" }}>: {data?.status_kawin}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NAMA SUAMI / ISTRI</th>
                  <td style={{ width: "50%" }}>: {data?.nama_pasangan}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>PEKERJAAN SUAMI / ISTRI</th>
                  <td style={{ width: "50%" }}>: {data?.pekerjaan_pasangan}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NIP (JIKA PNS)</th>
                  <td style={{ width: "50%" }}>: {data?.nip}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>KEPEGAWAIAN</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>STATUS PEGAWAI</th>
                  <td style={{ width: "50%" }}>: {data?.status_pegawai}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NIP (Jika PNS)</th>
                  <td style={{ width: "50%" }}>: {data?.nip}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NIY / NIGK</th>
                  <td style={{ width: "50%" }}>: {data?.niy}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NIGB</th>
                  <td style={{ width: "50%" }}>: {data?.nigb}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NUPTK</th>
                  <td style={{ width: "50%" }}>: {data?.nuptk}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>JENIS PTK</th>
                  <td style={{ width: "50%" }}>: {data?.jenis_ptk}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>STATUS AKTIF</th>
                  <td style={{ width: "50%" }}>: {data?.status_aktif}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>SK PENGANGKATAN</th>
                  <td style={{ width: "50%" }}>: {data?.sk_pengangkatan}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>TMT PENGANGKATAN</th>
                  <td style={{ width: "50%" }}>: {data?.tmt_pengangkatan}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>LEMBAGA PENGANGKATAN</th>
                  <td style={{ width: "50%" }}>
                    : {data?.lembaga_pengangkatan}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>SK CPNS</th>
                  <td style={{ width: "50%" }}>: {data?.sk_cpns}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>TMT CPNS</th>
                  <td style={{ width: "50%" }}>: {data?.tmt_cpns}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>TMT PNS</th>
                  <td style={{ width: "50%" }}>: {data?.tmt_pns}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>PANGKAT / GOLONGAN</th>
                  <td style={{ width: "50%" }}>: {data?.pangkat}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>SUMBER GAJI</th>
                  <td style={{ width: "50%" }}>: {data?.sumber_gaji}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>KARTU PEGAWAI</th>
                  <td style={{ width: "50%" }}>: {data?.kartu_pegawai}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>KARIS / KARSU</th>
                  <td style={{ width: "50%" }}>: {data?.karis}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 3px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>PENUGASAN</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>NO SURAT TUGAS</th>
                  <td style={{ width: "50%" }}>: {data?.no_surat}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>TANGGAL SURAT TUGAS</th>
                  <td style={{ width: "50%" }}>: {data?.tanggal_surat}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>TMT TUGAS</th>
                  <td style={{ width: "50%" }}>: {data?.tmt_tugas}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>SEKOLAH INDUK</th>
                  <td style={{ width: "50%" }}>: {data?.sekolah_induk}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>KOMPETENSI KHUSUS</h4>
            </div>
          </div>
          <div className="col-xs-12">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>LISENSI KEPALA SEKOLAH</th>
                  <td style={{ width: "50%" }}>: {data?.lisensi_kepsek}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>KODE PROGRAM KEAHLIAN</th>
                  <td style={{ width: "50%" }}>: {data?.kode_program}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>
                    Jika anda Guru yang menangani siswa berkebutuhan khusus,
                    jenis ketunaan apa saya yang anda tangani
                  </th>
                  <td style={{ width: "50%" }}>: {data?.keahlian}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>
                    Spesialisasi apa yang dimiliki untuk menangani kebutuhan
                    khusus
                  </th>
                  <td style={{ width: "50%" }}>: {data?.spesialis}</td>
                </tr>
                <tr>
                  <th style={{ width: "100%" }} colSpan="2">
                    Keahlian khusus apa saja yang dimilki untuk menangani
                    kebutuhan khusus
                  </th>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>BRAILE</th>
                  <td style={{ width: "50%" }}>: {data?.braile}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>BAHASA ISYARAT</th>
                  <td style={{ width: "50%" }}>: {data?.bahasa_isyarat}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>KONTAK</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>NO TELP</th>
                  <td style={{ width: "50%" }}>: {data?.no_telp}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>EMAIL</th>
                  <td style={{ width: "50%" }}>: {data?.email}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahKompetensi"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Kompetensi / Bidang SDM</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahKompetensi"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit(doCreateKompetensiBidang)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Kompetensi / Bidang SDM
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className={`box-body pad ${
                              errors?.txtStudi ? "has-error" : ""
                            }`}
                          >
                            <label>Bidang Studi</label>
                            <input
                              type="text"
                              name="txtStudi"
                              ref={register({ required: "required" })}
                              className="form-control"
                            />
                          </div>
                          <div className="box-body pad">
                            <label>Urutan</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtUrutan"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button type="submit" className="btn btn-success">
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Kompetensi / Bidang SDM
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <KompetensiBidang
              idPtk={id}
              deleteData={doDeleteKompetensiBidang}
            />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahAnak"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Anak</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahAnak"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit2(doCreateAnak)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Data Anak
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Status</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtStatus"
                              className="form-control"
                            />
                          </div>
                          <div className="box-body pad">
                            <label>Jenjang</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtJenjang"
                              className="form-control"
                            />
                          </div>
                          <div
                            className={`box-body pad ${
                              errors?.txtNISN ? "has-error" : ""
                            }`}
                          >
                            <label>NISN</label>
                            <input
                              type="text"
                              ref={register2({ required: "required" })}
                              name="txtNISN"
                              className="form-control"
                            />
                          </div>
                          <div className="box-body pad">
                            <label>Nama Anak</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtNama"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Jenis Kelamin</label>
                            <select
                              name="txtKelamin"
                              ref={register2()}
                              className="form-control"
                            >
                              <option value="L">Laki-Laki</option>
                              <option value="P">Perempuan</option>
                            </select>
                          </div>
                          <div className="box-body pad">
                            <label>Tempat Lahir</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtTempat"
                              className="form-control"
                            />
                          </div>
                          <div className="box-body pad">
                            <label>Tanggal Lahir</label>
                            <input
                              type="date"
                              name="txtTanggal"
                              className="form-control"
                            />
                          </div>
                          <div className="box-body pad">
                            <label>Tahun Masuk</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-anak"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Anak
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <Anak idPtk={id} deleteData={doDeleteAnak} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahBeasiswa"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Beasiswa</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahBeasiswa"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit3(doCreateBeasiswa)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Beasiswa
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className={`box-body pad ${
                              errors3?.txtJenis ? "has-error" : ""
                            }`}
                          >
                            <input
                              type="text"
                              ref={register3({ required: "required" })}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Penyelenggara</label>
                            <input
                              type="text"
                              ref={register3()}
                              name="txtPenyelenggara"
                              className="form-control"
                            />
                          </div>
                          <div className="box-body pad">
                            <label>Masih Menerima</label>
                            <select
                              name="txtMenerima"
                              ref={register3()}
                              className="form-control"
                            >
                              <option>Iya</option>
                              <option>Tidak</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Dari Tahun</label>
                            <input
                              type="text"
                              ref={register3()}
                              name="txtTahun1"
                              className="form-control"
                            />
                          </div>
                          <div className="box-body pad">
                            <label>Sampai Tahun</label>
                            <input
                              type="text"
                              ref={register3()}
                              name="txtTahun2"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-beasiswa"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Beasiswa
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <Beasiswa idPtk={id} deleteData={doDeleteBeasiswa} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahBuku"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Buku</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahBuku"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit4(doCreateBuku)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Buku
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className={`box-body pad ${
                              errors4?.txtJudul ? "has-error" : ""
                            }`}
                          >
                            <label>Judul</label>
                            <input
                              type="text"
                              ref={register4({ required: "required" })}
                              name="txtJudul"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun</label>
                            <input
                              type="text"
                              ref={register4()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Penerbit</label>
                            <input
                              type="text"
                              ref={register4()}
                              name="txtPenerbit"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-buku"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Buku
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <Buku idPtk={id} deleteData={doDeleteBuku} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahDiklat"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Diklat</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahDiklat"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit5(doCreateDiklat)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Diklat
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className={`box-body pad ${
                              errors5?.txtJenis ? "has-error" : ""
                            }`}
                          >
                            <label>Jenis Diklat</label>
                            <input
                              type="text"
                              ref={register5({ required: "required" })}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Nama</label>
                            <input
                              type="text"
                              ref={register5()}
                              name="txtNama"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Penyelenggara</label>
                            <input
                              type="text"
                              ref={register5()}
                              name="txtPenyelenggara"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun</label>
                            <input
                              type="text"
                              ref={register5()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Peran</label>
                            <input
                              type="text"
                              ref={register5()}
                              name="txtPeran"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-diklat"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Diklat
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <Diklat idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahKarya"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Karya Tulis</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahKarya"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit6(doCreateKarya)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Karya Tulis
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors6?.txtJudul ? "has-error" : ""
                            }`}
                          >
                            <label>Judul</label>
                            <input
                              type="text"
                              ref={register6({ required: "required" })}
                              name="txtJudul"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun</label>
                            <input
                              type="text"
                              ref={register6()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Publikasi</label>
                            <input
                              type="text"
                              ref={register6()}
                              name="txtPublikasi"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Keterangan</label>
                            <input
                              type="text"
                              ref={register6()}
                              name="txtKeterangan"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-karya"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Karya Tulis
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <KaryaTulis idPtk={id} deleteData={doDeleteKaryaTulis} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahKesejahteraan"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Kesejahteraan</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahKesejahteraan"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit7(doCreateKesejahteraan)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Kesejahteraan
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors7?.txtJenis ? "has-error" : ""
                            }`}
                          >
                            <label>Jenis</label>
                            <input
                              type="text"
                              ref={register7({ required: "required" })}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Nama</label>
                            <input
                              type="text"
                              ref={register7()}
                              name="txtNama"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Penyelenggara</label>
                            <input
                              type="text"
                              ref={register7()}
                              name="txtPenyelenggara"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Status</label>
                            <input
                              type="text"
                              ref={register7()}
                              name="txtStatus"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Dari Tahun</label>
                            <input
                              type="text"
                              ref={register7()}
                              name="txtTahun1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Sampai Tahun</label>
                            <input
                              type="text"
                              ref={register7()}
                              name="txtTahun2"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-kesejahteraan"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Kesejahteraan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <Kesejahteraan idPtk={id} deleteData={doDeleteKesejahteraan} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahTunjangan"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Tunjangan</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahTunjangan"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit8(doCreateTunjangan)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Tunjangan
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors8?.txtJenis ? "has-error" : ""
                            }`}
                          >
                            <label>Jenis</label>
                            <input
                              type="text"
                              ref={register8({ required: "required" })}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Nama</label>
                            <input
                              type="text"
                              ref={register8()}
                              name="txtNama"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Instansi</label>
                            <input
                              type="text"
                              ref={register8()}
                              name="txtInstansi"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Sumber Dana</label>
                            <input
                              type="text"
                              ref={register8()}
                              name="txtSumber"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Dari Tahun</label>
                            <input
                              type="text"
                              ref={register8()}
                              name="txtTahun1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Sampai Tahun</label>
                            <input
                              type="text"
                              ref={register8()}
                              name="txtTahun2"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Nominal</label>
                            <input
                              type="text"
                              ref={register8()}
                              name="txtNominal"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Status</label>
                            <input
                              type="text"
                              ref={register8()}
                              name="txtStatus"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-tunjangan"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Tunjangan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <Tunjangan idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahTugas"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Tugas Tambahan</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahTugas"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit9(doCreateTugas)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Tugas Tambahan
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="box-body pad">
                            <label>Jabatan PTK</label>
                            <input
                              type="text"
                              ref={register9()}
                              name="txtJabatan"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Jam Per Minggu</label>
                            <input
                              type="text"
                              ref={register9()}
                              name="txtJam"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Nomor SK</label>
                            <input
                              type="text"
                              ref={register9()}
                              name="txtNomor"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>TMT Tambahan</label>
                            <input
                              type="date"
                              name="txtTMT"
                              ref={register9()}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>TST Tambahan</label>
                            <input
                              type="date"
                              name="txtTST"
                              ref={register9()}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-tugas"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Tugas Tambahan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <TugasTambahan idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahPenghargaan"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Penghargaan</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahPenghargaan"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit10(doCreatePenghargaan)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Penghargaan
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="box-body pad">
                            <label>Tingkat</label>
                            <input
                              type="text"
                              ref={register10()}
                              name="txtTingkat"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors10?.txtJenis ? "has-error" : ""
                            }`}
                          >
                            <label>Jenis</label>
                            <input
                              type="text"
                              ref={register10({ required: "required" })}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Nama</label>
                            <input
                              type="text"
                              ref={register10()}
                              name="txtNama"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun</label>
                            <input
                              type="text"
                              ref={register10()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Instansi</label>
                            <input
                              type="text"
                              ref={register10()}
                              name="txtInstansi"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-penghargaan"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Penghargaan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <Penghargaan idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahNilai"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Nilai Tes</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahNilai"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit11(doCreateNilaiTes)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Nilai Tes
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className={`box-body pad ${
                              errors11?.txtJenis ? "has-error" : ""
                            }`}
                          >
                            <label>Jenis Tes</label>
                            <input
                              type="text"
                              ref={register11({ required: "req" })}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Nama</label>
                            <input
                              type="text"
                              ref={register11()}
                              name="txtNama"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Penyelenggara</label>
                            <input
                              type="text"
                              ref={register11()}
                              name="txtPenyelenggara"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun</label>
                            <input
                              type="text"
                              ref={register11()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Skor</label>
                            <input
                              type="text"
                              ref={register11()}
                              name="txtSkor"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-nilai"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Nilai Tes
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <NilaiTest idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahRiwayat"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Riwayat Gaji Berkala</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahRiwayat"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit12(doCreateGaji)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Riwayat Gaji Berkala
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors12?.txtPangkat ? "has-error" : ""
                            }`}
                          >
                            <label>Pangkat Golongan</label>
                            <input
                              type="text"
                              ref={register12({ required: "req" })}
                              name="txtPangkat"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>No SK</label>
                            <input
                              type="text"
                              ref={register12()}
                              name="txtNomor"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tanggal SK</label>
                            <input
                              type="date"
                              name="txtTanggal"
                              ref={register12()}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>TMT KGB</label>
                            <input
                              type="date"
                              name="txtTMT"
                              ref={register12()}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Masa Kerja Tahun</label>
                            <input
                              type="text"
                              ref={register12()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Masa Kerja Bulan</label>
                            <input
                              type="text"
                              ref={register12()}
                              name="txtBulan"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="box-body pad">
                            <label>Gaji Pokok</label>
                            <input
                              type="text"
                              ref={register12()}
                              name="txtGaji"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-riwayat"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Riwayat Gaji Berkala
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <RiwayatGaji idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahRiwayat2"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Riwayat Jabatan Struktural</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahRiwayat2"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit13(doCreateJabatanStruktural)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Riwayat Jabatan Struktural
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className={`box-body pad ${
                              errors13?.txtJabatan ? "has-error" : ""
                            }`}
                          >
                            <label>Jabatan PTK</label>
                            <input
                              type="text"
                              ref={register13({ required: "required" })}
                              name="txtJabatan"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="box-body pad">
                            <label>SK Struktural</label>
                            <input
                              type="text"
                              ref={register13()}
                              name="txtSK"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="box-body pad">
                            <label>TMT Jabatan</label>
                            <input
                              type="date"
                              name="txtTMT"
                              ref={register13()}
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-riwayat2"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Riwayat Jabatan Struktural
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <RiwayatJabatan idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahRiwayat3"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Riwayat Kepangkatan</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahRiwayat3"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit14(doCreateRiwayatKepangkatan)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Riwayat Kepangkatan
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors14?.txtPangkat ? "has-error" : ""
                            }`}
                          >
                            <label>Pangkat</label>
                            <input
                              type="text"
                              ref={register14({ required: "required" })}
                              name="txtPangkat"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>No SK</label>
                            <input
                              type="text"
                              ref={register14()}
                              name="txtSK"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tanggal SK</label>
                            <input
                              type="date"
                              ref={register14()}
                              name="txtTanggal"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Golongan</label>
                            <input
                              type="text"
                              ref={register14()}
                              name="txtGolongan"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Masa Kerja Tahun</label>
                            <input
                              type="text"
                              ref={register14()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Masa Kerja Bulan</label>
                            <input
                              type="text"
                              ref={register14()}
                              name="txtBulan"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-riwayat3"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Riwayat Kepangkatan
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <RiwayatKepangkatan idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahRiwayat4"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Riwayat Pendidikan Formal</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahRiwayat4"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit15(doCreateRiwayatPendidikan)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Riwayat Pendidikan Formal
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors15?.txtBidang ? "has-error" : ""
                            }`}
                          >
                            <label>Bidang Studi</label>
                            <input
                              type="text"
                              ref={register15({ required: "required" })}
                              name="txtBidang"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Jenjang</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtJenjang"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Gelar</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtGelar"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Satuan</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtSatuan"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Fakultas</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtFakultas"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Kependidikan</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtKependidikan"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun Masuk</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtTahun1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun Lulus</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtTahun2"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>NIM</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtNIM"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Masih</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtMasih"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>SMT</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtSMT"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>IPK</label>
                            <input
                              type="text"
                              ref={register15()}
                              name="txtIPK"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-riwayat4"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Riwayat Pendidikan Formal
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <RiwayatPendidikan idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahRiwayat5"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Riwayat Sertifikasi</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahRiwayat5"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit16(doCreateRiwayatSertifikasi)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Riwayat Sertifikasi
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors16?.txtJenis ? "has-error" : ""
                            }`}
                          >
                            <label>Jenis Sertifikasi</label>
                            <input
                              type="text"
                              ref={register16({ required: "required" })}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>No. Sertifikasi</label>
                            <input
                              type="text"
                              ref={register16()}
                              name="txtNomor"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun Sertifikasi</label>
                            <input
                              type="text"
                              ref={register16()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Bidang Studi</label>
                            <input
                              type="text"
                              ref={register16()}
                              name="txtBidang"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>NRG</label>
                            <input
                              type="text"
                              ref={register16()}
                              name="txtNRG"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>No. Peserta</label>
                            <input
                              type="text"
                              ref={register16()}
                              name="txtPeserta"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-riwayat5"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Riwayat Sertifikasi
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <RiwayatSertifikasi idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahRiwayat6"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Riwayat Jabatan Fungsional</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahRiwayat6"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit17(doCreateJabatanFungsional)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Riwayat Jabatan Fungsional
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div
                            className={`box-body pad ${
                              errors17?.txtJabatan ? "has-error" : ""
                            }`}
                          >
                            <label>Jabatan Fungsional</label>
                            <input
                              type="text"
                              ref={register17({ required: "required" })}
                              name="txtJabatan"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>SK Jabatan Fungsional</label>
                            <input
                              type="text"
                              ref={register17()}
                              name="txtSK"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>TMT</label>
                            <input
                              type="date"
                              ref={register17()}
                              name="txtTMT"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-riwayat6"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Riwayat Jabatan Fungsional
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <RiwayatJabatanFunctional idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahRiwayat7"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Riwayat Karir Guru</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahRiwayat7"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit18(doCreateKarirGuru)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Riwayat Karir Guru
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div
                            className={`box-body pad ${
                              errors18?.txtJenjang ? "has-error" : ""
                            }`}
                          >
                            <label>Jenjang</label>
                            <input
                              type="text"
                              ref={register18({ required: "required" })}
                              name="txtJenjang"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Jenis Lembaga</label>
                            <input
                              type="text"
                              ref={register18()}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Sts Kepegawaian</label>
                            <input
                              type="text"
                              ref={register18()}
                              name="txtSts"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Jenis PTK</label>
                            <input
                              type="text"
                              ref={register18()}
                              name="txtPTK"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Lembaga Pengangkat</label>
                            <input
                              type="text"
                              ref={register18()}
                              name="txtLembaga"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>No SK</label>
                            <input
                              type="text"
                              ref={register18()}
                              name="txtSK"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tgl SK</label>
                            <input
                              type="date"
                              ref={register18()}
                              name="txtTanggal"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>TMT</label>
                            <input
                              type="date"
                              name="txtTMT"
                              ref={register18()}
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>TST</label>
                            <input
                              type="date"
                              ref={register18()}
                              name="txtTST"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tempat</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtTempat"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>TTD SK</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtTTD"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Mapel Diajarkan</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtMapel"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-riwayat7"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Riwayat Karir Guru
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <RiwayatKarirGuru idPtk={id} deleteData={doDeleteDiklat} />
          </div>
          <div className="col-xs-12">
            <p>
              Yang bertanda tangan di bawah ini bertanggung jawab secara hukum
              terhadap kebenaran data yang tercantum.
              <br />
              Mengetahui,
            </p>
          </div>
          <div className="col-xs-6">
            <p className="margin">
              <strong>Kepala SEKOLAH PELOPOR DURI</strong>
              <br />
              <br />
              <br />
              <br />
              <br />
              Nama Kepala
              <br />
              NIK:{" "}
            </p>
          </div>
          <div className="col-xs-6">
            <p className="margin">
              <strong>Pendidik/Tenaga Kependidikan</strong>
              <br />
              <br />
              <br />
              <br />
              <br />
              {data?.nama}
              <br />
              NIK: {data?.nik}
            </p>
          </div>
        </div>
      </section>
    </Content>
  );
};

export default DetailPTK;
