import React from "react";
import { Content } from "adminlte-2-react";
import { submit } from "components/utils/proxy";
import {
  // useMutation,
  useQuery,
  // useQueryClient
} from "react-query";

const Detail = (props) => {
  const { value, match } = props;
  const { id } = match?.params;
  let arrayId = id.split("-");
  let idKelas = arrayId[0];
  let tahun = `${arrayId[1]}/${arrayId[2]}`;
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [limit] = React.useState(10);
  const [jumlahData, setJumlahData] = React.useState(1);
  const [search, setSearch] = React.useState("");

  const { isLoading, error, data } = useQuery(
    ["detail-rekap-kelas", { value, page, search }],
    async () => {
      var params = "";
      if (search !== "") {
        params = `&search=${search}`;
      }
      let res = await submit("POST", `api/page/kelas/getById`, {
        id: idKelas,
        tahun: tahun,
      });
      return res.data;
    }
  );
  return (
    <React.Fragment>
      <Content
        title="Detail Kelas"
        subTitle=" SEKOLAH PELOPOR"
        homeRoute={`${process.env.PUBLIC_URL}/dashboard/rekap-kelas`}
        browserTitle="Detail Kelas"
      >
        <section className="content box box-primary">
          <div className="row">
            <div className="col-md-12">
              <table className="table">
                <thead>
                  <tr role="row">
                    <th
                      className="sorting_asc"
                      tabindex="0"
                      aria-controls="tabelumumnopaging"
                      rowspan="1"
                      colspan="1"
                      aria-sort="ascending"
                      aria-label="#: activate to sort column descending"
                      style={{ width: 15 }}
                    >
                      #
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="tabelumumnopaging"
                      rowspan="1"
                      colspan="1"
                      aria-label="NIS: activate to sort column ascending"
                      style={{ width: 31 }}
                    >
                      NIS
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="tabelumumnopaging"
                      rowspan="1"
                      colspan="1"
                      aria-label="Nama: activate to sort column ascending"
                      style={{ width: 270 }}
                    >
                      Nama
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="tabelumumnopaging"
                      rowspan="1"
                      colspan="1"
                      aria-label="Jenis Kelamin: activate to sort column ascending"
                      style={{ width: 107 }}
                    >
                      Jenis Kelamin
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="tabelumumnopaging"
                      rowspan="1"
                      colspan="1"
                      aria-label="Email: activate to sort column ascending"
                      style={{ width: 46 }}
                    >
                      Email
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="tabelumumnopaging"
                      rowspan="1"
                      colspan="1"
                      aria-label="No Handphone: activate to sort column ascending"
                      style={{ width: 113 }}
                    >
                      No Handphone
                    </th>
                    <th
                      className="sorting"
                      tabindex="0"
                      aria-controls="tabelumumnopaging"
                      rowspan="1"
                      colspan="1"
                      aria-label="Kelas Tahun Ajaran 2020/2021: activate to sort column ascending"
                      style={{ width: 221 }}
                    >
                      Kelas Tahun Ajaran {tahun}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.length === 0 && (
                    <tr className="text-center">
                      <td colSpan={10}>Data tidak ditemukan</td>
                    </tr>
                  )}
                  {data?.map((row, index) => {
                    return (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{row?.nis}</td>
                        <td>{row?.nama_lengkap}</td>
                        <td>{row?.jenis_kelamin}</td>
                        <td>{row?.email}</td>
                        <td>{row?.no_handphone}</td>
                        <td>
                          {row?.nama_kelas}{" "}
                          {`(${row?.nama_jenjang} ${row?.tingkat_jenjang})`}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </section>
      </Content>
    </React.Fragment>
  );
};

export default Detail;
