import React from "react";
import { Helmet } from "react-helmet";
import Layout from "components/PublicLayout";
import Banner from "components/Section/banner";
import Artikel from "components/Artikel";
import Event from "components/Event";
import SideMenu from "components/Section/sidemenu";
// import ApiService from "services/ApiService";
import axios from "axios";
// import Skeleton from "react-loading-skeleton";
import moment from "moment";
import {
  // useMutation,
  useQuery,
  // useQueryClient
} from "react-query";

const DetailPrestasi = (props) => {
  const { history, match } = props;
  const { params } = match;
  const { slug } = params;
  const { isLoading, isSuccess, error, data } = useQuery(
    ["detailPrestasi", { slug }],
    async () => {
      let res = await axios(
        `${process.env.REACT_APP_API_URL}api/page/home/getDetailPrestasi?slug=${slug}`
      );
      res = res.data;
      return res.data;
    }
  );

  React.useEffect(() => {
    if (slug) {
      window.scrollTo(0, 0);
    }
  }, [slug]);

  return (
    <Layout>
      <Helmet titleTemplate="Irene - Auth" defaultTitle="Detail">
        <meta name="description" content="Detail of Irene Dashboard" />
      </Helmet>
      <div className="main-content">
        <Banner title={data?.judul_prestasi} history={history} />
        <section>
          <div className="container mt-30 mb-30 pt-30 pb-30">
            <div className="row">
              <div className="col-md-12">
                <div className="blog-posts single-post">
                  <article className="post clearfix mb-0">
                    <div className="entry-header">
                      <div className="post-thumb thumb">
                        <img
                          src={`${process.env.REACT_APP_API_URL}api/image/get/featured/${data?.gambar_prestasi}`}
                          alt=""
                          className="img-responsive img-fullwidth"
                        />
                      </div>
                    </div>
                    <div className="entry-content">
                      <div className="entry-meta media no-bg no-border mt-15 pb-20">
                        <div className="entry-date media-left text-center flip bg-theme-colored pt-5 pr-15 pb-5 pl-15">
                          <ul>
                            <li className="font-16 text-white font-weight-600">
                              {moment(data?.tgl_prestasi).format("D")}
                            </li>
                            <li className="font-12 text-white text-uppercase">
                              {moment(data?.tgl_prestasi).format("MMM")}
                            </li>
                          </ul>
                        </div>
                        <div className="media-body pl-15">
                          <div className="event-content pull-left flip">
                            <h3 className="entry-title text-uppercase pt-0 mt-0">
                              {data?.judul_prestasi}
                            </h3>
                            <span className="mb-10 text-gray-darkgray mr-10 font-13">
                              <i className="fa fa-user mr-5 text-theme-colored"></i>{" "}
                              {data?.name}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div
                        dangerouslySetInnerHTML={{ __html: data?.isi_prestasi }}
                      ></div>
                    </div>
                  </article>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default DetailPrestasi;
