import React from "react";
import { submit } from "components/utils/proxy";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Pagination from "react-js-pagination";
import {
  // useMutation,
  useQuery,
  // useQueryClient
} from "react-query";

const TugasTambahan = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { value, idPtk, history } = props;
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [limit] = React.useState(10);
  const [jumlahData, setJumlahData] = React.useState(1);
  const [search, setSearch] = React.useState("");

  const { isLoading, error, data } = useQuery(
    ["TugasTambahan", { value, page, search }],
    async () => {
      var params = "";
      if (search !== "") {
        params = `&search=${search}`;
      }
      let res = await submit(
        "POST",
        `api/page/directoryPTK/getTugasTambahan?limit=${limit}&page=${page}${params}&id_ptk=${idPtk}`
      );

      setTotalPage(res.total_pages);
      setJumlahData(res.jumlah_data);
      return res.data;
    }
  );
  return (
    <React.Fragment>
      {error && <div>Something went wrong ...</div>}
      <div
        className="col-md-6"
        style={{ paddingLeft: 0, paddingBottom: 5 }}
      ></div>
      <div
        className="col-md-6 no-print"
        style={{ paddingRight: 0, paddingBottom: 5 }}
      >
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={search}
            placeholder="Search.."
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="input-group-btn">
            <button type="button" className="btn btn-success">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <table className="table">
          <thead>
            <tr>
              <th style={{ width: 10 }}>#</th>
              <th>
                <Skeleton />
              </th>
              <th>
                <Skeleton />
              </th>
              <th>
                <Skeleton />
              </th>
              <th>
                <Skeleton />
              </th>
              <th>
                <Skeleton />
              </th>
              <th>
                <Skeleton />
              </th>
            </tr>
          </thead>
          <tbody>
            {Array.from(new Array(limit)).map((value, key) => {
              return (
                <tr key={key}>
                  <td>
                    <Skeleton width={10} />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                  <td>
                    <Skeleton />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <table className="table">
          <thead>
            <tr>
              <th>#</th>
              <th>Jabatan PTK</th>
              <th>Jam Per Minggu</th>
              <th>Nomor SK</th>
              <th>TMT Tambahan</th>
              <th>TST Tambahan</th>
              <td className="no-print"></td>
            </tr>
          </thead>
          <tbody>
            {data?.length === 0 && (
              <tr className="text-center">
                <td colSpan={7}>Data tidak ditemukan</td>
              </tr>
            )}
            {data?.map((row, index) => {
              return (
                <tr key={index}>
                  <td>{index + 1}</td>
                  <td>{row.jabatan_ptk}</td>
                  <td>{row.jam}</td>
                  <td>{row.nomor_sk}</td>
                  <td>{moment(row.tmt_tambahan).format("DD MMMM YYYY")}</td>
                  <td>{moment(row.tst_tambahan).format("DD MMMM YYYY")}</td>
                  <td style={{ textAlign: "center" }}>
                    <a
                      href="#"
                      onClick={(e) => props.deleteData(e, row)}
                      className="btn btn-danger  btn-xs btn-flat no-print"
                    >
                      <i className="fa fa-trash"></i>
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div className="no-print">
        <Pagination
          activePage={page}
          itemsCountPerPage={limit}
          totalItemsCount={jumlahData}
          pageRangeDisplayed={5}
          onChange={setPage}
        />
      </div>
    </React.Fragment>
  );
};

export default TugasTambahan;
