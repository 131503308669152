import Artikel from "components/Artikel";
import { withRouter } from "react-router-dom";
import logoWhite from "assets/img/logo-wide-white.png";
const Footer = (props) => {
  const { history } = props;
  return (
    <footer id="footer" className="footer bg-black-111">
      <div className="container pt-70 pb-40">
        <div className="row border-bottom-black">
          <div className="col-sm-6 col-md-3">
            <div className="widget dark">
              <img className="mt-10 mb-20" alt="img" src={logoWhite} />
              <p>
               Sekolah Pelopor adalah salah satu sekolah swasta di Duri
               yang berada dibawah naungan Yayasan Pubbarama Duri. Sekolah pelopor terdiri dari tiga unit, Yaitu PAUD Pelopor, SDS Pelopor Duri, SMPS Pelopor Mandau.
               
              </p>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="widget dark">
              <h5 className="widget-title">Kontak Kami</h5>
              <ul className="list-inline mt-5">
                <li className="m-0 pl-10 pr-10">
                  <i className="fa fa-map-marker text-theme-color-2 mr-5"></i>
                  <a className="text-gray" href="#">
                    JL KAMPUNG LALANG, BENGKALIS, RIAU
                  </a>
                </li>
                <li className="m-0 pl-10 pr-10">
                  <i className="fa fa-phone text-theme-color-2 mr-5"></i>
                  <a className="text-gray" href="#">
                    0821-8528-2322
                  </a>
                </li>
                {/* <li className="m-0 pl-10 pr-10">
                  <i className="fa fa-envelope-o text-theme-color-2 mr-5"></i>
                  <a className="text-gray" href="#">
                    info@namadomain.com
                  </a>
                </li> */}
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="widget dark">
              <h4 className="widget-title">Siswa</h4>
              <ul className="list-border">
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/jadwal-siswa`);
                    }}
                  >
                    Jadwal Kelas
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/kehadiran-siswa`);
                    }}
                  >
                    Kehadiran
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/raport-siswa`);
                    }}
                  >
                    Raport
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="col-sm-6 col-md-3">
            <div className="widget dark">
              <h4 className="widget-title">Pembelajaran</h4>
              <ul className="list-border">
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/guru`);
                    }}
                  >
                    Guru
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/download-materi`);
                    }}
                  >
                    Download Materi
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    onClick={() => {
                      history.push(`${process.env.PUBLIC_URL}/kelas-online`);
                    }}
                  >
                    Kelas Online
                  </a>
                </li>
              </ul>
            </div>

          </div>
        </div>
      </div>
      <div className="footer-bottom bg-black-222">
        <div className="container pt-10 pb-0">
          <div className="row">
            <div className="col-md-6 sm-text-center">
              <p className="font-13 text-black-777 m-0">
                Copyright ©2020 SEKOLAH PELOPOR DURI. All Rights Reserved
              </p>
            </div>
            <div className="col-md-6 text-right flip sm-text-center">
              <div className="widget no-border m-0">
                <ul className="styled-icons icon-dark icon-circled icon-sm">
                  <li>
                    <a href="#">
                      <i className="fa fa-facebook"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-twitter"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-skype"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-youtube"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-instagram"></i>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <i className="fa fa-pinterest"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default withRouter(Footer);
