import Axios from "axios";
const defaultHeader = async () => {
  const token = localStorage.getItem("token");
  let headerData;
  if (token) {
    headerData = {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Bearer ${token}`,
    };
  } else {
    headerData = {
      Accept: "application/json",
      "Content-Type": "application/json",
    };
  }
  return headerData;
};

const ApiService = async () => {
  const header = await defaultHeader();

  const instance = Axios.create({
    baseURL: process.env.REACT_APP_API_URL + "api/",
    timeout: 60000,
    headers: header,
  });

  return instance;
};

export default ApiService;
