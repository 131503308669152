import React from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import {
  // useMutation,
  useQuery,
  // useQueryClient
} from "react-query";

const Event = (props) => {
  const { history , jenjang } = props;

  // const getArticles = async () => {
  //   const instance = await ApiService();
  //   let res = instance.get("/page/home/getEvent");

  //   return res;
  // };

  // const { isLoading, error, data } = useQuery("articleList", () => getEvent);

  const { isLoading, error, data } = useQuery("eventList", async () => {
    let res = await axios(
      `${process.env.REACT_APP_API_URL}api/page/home/getEvent?limit=5&jenjang=${jenjang}`
    );
    res = res.data;
    return res.data;
  });

  return (
    <React.Fragment>
      {error && <div>Something went wrong ...</div>}

      {isLoading ? (
        <React.Fragment>
          {Array.from(new Array(4)).map(() => (
            <article className="post media-post clearfix pb-0 mb-10 cursor-pointer">
              <div className="post-thumb">
                <Skeleton width={100} height={50} />
              </div>
              <div className="post-right">
                <h5 className="post-title mt-0 mb-5">
                  <Skeleton count={2} />
                </h5>
                <p className="post-date mb-0 font-12">
                  <Skeleton width={100} />
                </p>
              </div>
            </article>
          ))}
        </React.Fragment>
      ) : (
        <React.Fragment>
          {data?.map((value, key) => (
            <article
              className="post media-post clearfix pb-0 mb-10 cursor-pointer"
              key={key}
            >
              <div
                onClick={() =>
                  history.push(
                    `${process.env.PUBLIC_URL}/detail/${value.url_event}`
                  )
                }
                className="post-thumb"
              >
                <img
                  alt={value.judul_event}
                  src={`${process.env.REACT_APP_API_URL}api/image/get/featured/${value.gambar_event}`}
                  style={{ maxWidth: 80 }}
                />
              </div>
              <div className="post-right">
                <h5 className="post-title mt-0 mb-5">
                  <a
                    onClick={() =>
                      history.push(
                        `${process.env.PUBLIC_URL}/detail/${value.url_event}`
                      )
                    }
                  >
                    {value.judul_event}
                  </a>
                </h5>
                <p className="post-date mb-0 font-12">
                  {moment(value.tgl_event).format("MMM Do YYYY, h:mm:ss a")}
                </p>
              </div>
            </article>
          ))}
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default Event;
