import Header from "./header";
import Footer from "./footer";
import "styles/load-styles.scss";
import React from "react";

const Layout = (props) => {
  document.getElementById("root").className = "clearfix";
  return (
    <React.Fragment>
      <Header />
      {props.children}
      <Footer />
    </React.Fragment>
  );
};

export default Layout;
