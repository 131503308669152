import imgProfile from "assets/img/bg/bg6.jpg";

const Banner = (props) => {
  const { title, history } = props;
  return (
    <section
      className="inner-header divider parallax layer-overlay overlay-white-8"
      databgimg={{ imgProfile }}
      style={{
        backgroundImage: `url(${imgProfile})`,
        backgroundPosition: "50% 61px",
      }}
    >
      <div className="container pt-100 pb-100">
        <div className="section-content">
          <div className="row">
            <div className="col-md-12 text-center">
              <h2 className="title">{title}</h2>
              <ol className="breadcrumb text-center mt-10">
                <li>
                  <a onClick={() => history.push("/")}>Halaman Utama</a>
                </li>
                <li className="active text-theme-colored">{title}</li>
              </ol>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
