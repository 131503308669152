import AdminLTE, { Sidebar, Navbar } from "adminlte-2-react";
import userImg from "assets/img/default.png";
import logo from "assets/img/logo.png";
import logoMini from "assets/img/logo-mini.png";
import { confirm } from "components/ConfirmDialog";
import { withRouter } from "react-router-dom";

const { Item, UserPanel } = Sidebar;
const { Entry } = Navbar;

const Layout = (props) => {
  const user = JSON.parse(localStorage.getItem("user"));
  const { history } = props;
  const handleLogout = () => {
    confirm("Logout", "Are you sure to Logout ?").then(async (onProcess) => {
      localStorage.removeItem("token");
      history.push(`${process.env.PUBLIC_URL}/login`);
      window.location.reload();
    });
  };
  return (
    <AdminLTE
      title={<img src={logo} alt="logo" className="img-responsive" />}
      homeTo={`${process.env.PUBLIC_URL}/dashboard/home`}
      titleShort={
        <img src={logoMini} alt="logo-mini" className="img-responsive" />
      }
      theme="blue-light"
      modal
    >
      <Navbar.Core>
        <div className="navbar-custom-menu">
          <ul className="nav navbar-nav">
            <li className="dropdown user user-menu">
              <a
                href="#"
                className="dropdown-toggle"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <img
                  src={`${process.env.REACT_APP_API_URL}api/image/get/featured/${user?.foto_admin}`}
                  className="user-image"
                  alt="User Image"
                />
                <span className="hidden-xs">{user?.name}</span>
              </a>
              <ul className="dropdown-menu">
                <li className="user-header">
                  <img
                    src={`${process.env.REACT_APP_API_URL}api/image/get/featured/${user?.foto_admin}`}
                    className="img-circle"
                    alt="User Image"
                  />
                  <p>
                    {user?.name} <small>{user?.roleName}</small>
                  </p>
                </li>
                <li className="user-footer">
                  <div className="pull-left">
                    <a href="#" className="btn btn-default btn-flat">
                      <Item
                        key="update-profile"
                        text="Profile"
                        to={`${process.env.PUBLIC_URL}/dashboard/profile`}
                      />
                    </a>
                  </div>
                  <div className="pull-right">
                    <a
                      href="#"
                      onClick={handleLogout}
                      className="btn btn-default btn-flat"
                    >
                      Sign out
                    </a>
                  </div>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </Navbar.Core>
      <Sidebar.Core>
        <UserPanel
          username={user?.name}
          statusType="success"
          status="online"
          imageUrl={`${process.env.REACT_APP_API_URL}api/image/get/featured/${user?.foto_admin}`}
        />
        <Item
          key="dashboard"
          text="Dashboard"
          icon="fa-tachometer-alt"
          to={`${process.env.PUBLIC_URL}/dashboard/home`}
        />
        {user?.roleName !== "Yayasan" && (
          <Item key="pembelajaran" icon="fa-book" text="Pembelajaran">
            <Item
              key="upload-materi"
              text="Upload Materi"
              to={`${process.env.PUBLIC_URL}/dashboard/upload-materi`}
              isSubItem
            />
            <Item
              key="kelas-online"
              text="Kelas Online"
              to={`${process.env.PUBLIC_URL}/dashboard/kelas-online`}
              isSubItem
            />
          </Item>
        )}
        {(user?.roleName === "Yayasan" ||
          user?.roleName === "Kepala Sekolah" ||
          user?.roleName === "Waka Kesiswaan" ||
          user?.roleName === "Waka Kurikulum" ||
          user?.roleName === "Waka Sarpras" ||
          user?.roleName === "Administrator") && (
          <Item key="kelas" icon="fa-sitemap" text="Kelas">
            <Item
              key="pengaturan-kelas"
              text="Pengaturan Kelas"
              to={`${process.env.PUBLIC_URL}/dashboard/pengaturan-kelas`}
              isSubItem
            />
            {user?.roleName !== "Yayasan" &&
              user?.roleName !== "Kepala Sekolah" &&
              user?.roleName !== "Waka Kurikulum" &&
              user?.roleName !== "Waka Sarpras" && (
                <Item
                  key="rekap-kelas"
                  text="Rekap Kelas"
                  to={`${process.env.PUBLIC_URL}/dashboard/rekap-kelas`}
                  isSubItem
                />
              )}
          </Item>
        )}
        {(user?.roleName === "Yayasan" ||
          user?.roleName === "Kepala Sekolah" ||
          user?.roleName === "Waka Kesiswaan" ||
          user?.roleName === "Waka Kurikulum" ||
          user?.roleName === "Waka Sarpras" ||
          user?.roleName === "Administrator") && (
          <Item
            key="jadwal"
            text="Jadwal"
            icon="fa-calendar-alt"
            to={`${process.env.PUBLIC_URL}/dashboard/data-jadwal`}
          />
        )}
        {/* {(user?.roleName === "Kepala Sekolah" ||
          user?.roleName === "Waka Kesiswaan" ||
          user?.roleName === "Waka Kurikulum" ||
          user?.roleName === "Waka Sarpras" ||
          user?.roleName === "Guru" ||
          user?.roleName === "Administrator") &&
          user?.unit?.jenjang_unit !== "SMP" && (
            <Item key="penilaian" icon="fa-desktop" text="Penilaian">
              {user?.roleName !== "Waka Kesiswaan" &&
                user?.roleName !== "Kepala Sekolah" &&
                user?.roleName !== "Waka Kurikulum" &&
                user?.roleName !== "Waka Sarpras" && (
                  <Item
                    key="masukan-nilai"
                    text="Masukan Nilai"
                    to={`${process.env.PUBLIC_URL}/dashboard/data-penilaian-siswa`}
                    isSubItem
                  />
                )}
              <Item
                key="rekap-nilai"
                text="Rekap Nilai"
                to={`${process.env.PUBLIC_URL}/dashboard/rekap-nilai`}
                isSubItem
              />
            </Item>
          )} */}
        {(user?.roleName === "Kepala Sekolah" ||
          user?.roleName === "Waka Kesiswaan" ||
          user?.roleName === "Waka Kurikulum" ||
          user?.roleName === "Waka Sarpras" ||
          user?.roleName === "Guru" ||
          user?.roleName === "Administrator") && (
          <Item key="absensi" icon="fa-tags" text="Absensi">
            {user?.roleName !== "Waka Kesiswaan" &&
              user?.roleName !== "Kepala Sekolah" &&
              user?.roleName !== "Waka Kurikulum" &&
              user?.roleName !== "Waka Sarpras" && (
                <Item
                  key="data-absensi-harian"
                  text="Absensi Harian"
                  to={`${process.env.PUBLIC_URL}/dashboard/data-absensi-harian`}
                  isSubItem
                />
              )}
            <Item
              key="rekap-absensi"
              text="Rekap Absensi"
              to={`${process.env.PUBLIC_URL}/dashboard/rekap-absensi`}
              isSubItem
            />
          </Item>
        )}
        {(user?.roleName === "Yayasan" ||
          user?.roleName === "Kepala Sekolah" ||
          user?.roleName === "Waka Kesiswaan" ||
          user?.roleName === "Waka Kurikulum" ||
          user?.roleName === "Waka Sarpras" ||
          user?.roleName === "Guru" ||
          user?.roleName === "Administrator") && (
          <Item
            key="sarana-dan-prasarana"
            icon="fa-building"
            text="Sarana dan Prasarana"
          >
            <Item
              key="laporan-kerusakan"
              text="Laporan Kerusakan"
              to={`${process.env.PUBLIC_URL}/dashboard/laporan-kerusakan`}
              isSubItem
            />
            <Item
              key="data-snp"
              text="Data Sarana dan Prasarana"
              to={`${process.env.PUBLIC_URL}/dashboard/data-sarana-prasarana`}
              isSubItem
            />
          </Item>
        )}
        {(user?.roleName === "Yayasan" ||
          user?.roleName === "Administrator") && (
          <Item
            key="data-pengurus"
            text="Manajemen Pengguna"
            icon="fa-user-secret"
            to={`${process.env.PUBLIC_URL}/dashboard/data-pengurus-website`}
          />
        )}
        {user?.roleName === "Administrator" && (
          <Item key="website" icon="fa-globe" text="Website">
            <Item
              key="artikel"
              text="Artikel"
              to={`${process.env.PUBLIC_URL}/dashboard/data-artikel`}
              isSubItem
            />
            <Item
              key="event"
              text="Event"
              to={`${process.env.PUBLIC_URL}/dashboard/data-event`}
              isSubItem
            />
            <Item
              key="prestasi"
              text="Prestasi"
              to={`${process.env.PUBLIC_URL}/dashboard/data-prestasi`}
              isSubItem
            />
            <Item
              key="fasilitas"
              text="Fasilitas"
              to={`${process.env.PUBLIC_URL}/dashboard/data-fasilitas`}
              isSubItem
            />
            <Item
              key="extrakulikuler"
              text="Extrakulikuler"
              to={`${process.env.PUBLIC_URL}/dashboard/data-extrakulikuler`}
              isSubItem
            />
            <Item
              key="slider"
              text="Slider"
              to={`${process.env.PUBLIC_URL}/dashboard/data-slider`}
              isSubItem
            />
            <Item key="halaman" text="Halaman" isSubItem>
              <Item
                key="profile"
                text="Profile"
                to={`${process.env.PUBLIC_URL}/dashboard/halaman-profil`}
                isSubItem
              />
              <Item
                key="visi-misi"
                text="Visi Misi"
                to={`${process.env.PUBLIC_URL}/dashboard/halaman-visi`}
                isSubItem
              />
              <Item
                key="sejarah"
                text="Sejarah"
                to={`${process.env.PUBLIC_URL}/dashboard/halaman-sejarah`}
                isSubItem
              />
            </Item>
          </Item>
        )}
        {(user?.roleName === "Administrator" ||
          user?.roleName === "Waka Kesiswaan") && (
          <Item key="pengaturan" icon="fa-cogs" text="pengaturan">
            {user?.roleName === "Administrator" && (
              <Item
                key="jenjang"
                text="Jenjang"
                to={`${process.env.PUBLIC_URL}/dashboard/data-jenjang`}
                isSubItem
              />
            )}
            {user?.roleName === "Administrator" && (
              <Item
                key="kelas"
                text="Kelas"
                to={`${process.env.PUBLIC_URL}/dashboard/data-kelas`}
                isSubItem
              />
            )}
            {user?.roleName === "Administrator" && (
              <Item
                key="Unit"
                text="Unit"
                to={`${process.env.PUBLIC_URL}/dashboard/data-unit`}
                isSubItem
              />
            )}
            {user?.roleName === "Administrator" && (
              <Item
                key="Gedung"
                text="Gedung"
                to={`${process.env.PUBLIC_URL}/dashboard/data-gedung`}
                isSubItem
              />
            )}
            <Item
              key="mata-pelajaran"
              text="Mata Pelajaran"
              to={`${process.env.PUBLIC_URL}/dashboard/data-mapel`}
              isSubItem
            />
          </Item>
        )}
        {(user?.roleName === "Administrator" ||
          user?.roleName === "Bimbingan Konseling") && (
          <Item
            key="bimbingan-konseling"
            icon="fa-users"
            text="Bimbingan Konseling"
          >
            {(user?.roleName === "Administrator" ||
              user?.roleName === "Bimbingan Konseling") && (
              <Item
                key="pelanggaran"
                text="Pelanggaran"
                to={`${process.env.PUBLIC_URL}/dashboard/data-jenis-pelanggaran`}
                isSubItem
              />
            )}
            {(user?.roleName === "Administrator" ||
              user?.roleName === "Bimbingan Konseling") && (
              <Item
                key="sanksi"
                text="Sanksi"
                to={`${process.env.PUBLIC_URL}/dashboard/data-sanksi`}
                isSubItem
              />
            )}
            {(user?.roleName === "Administrator" ||
              user?.roleName === "Bimbingan Konseling") && (
              <Item
                key="data-pelanggaran"
                text="Data Pelanggaran"
                to={`${process.env.PUBLIC_URL}/dashboard/data-pelanggaran`}
                isSubItem
              />
            )}
          </Item>
        )}
      </Sidebar.Core>
      {props.children}
    </AdminLTE>
  );
};
export default withRouter(Layout);
