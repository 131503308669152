import loadable from "utils/loadable";
import Loading from "components/Loading";

export default loadable(
  () => {
    return new Promise((resolve) => {
      setTimeout(() => resolve(import("./index")), 3000);
    });
  },
  {
    fallback: <Loading />,
  }
);
