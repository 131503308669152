import React from "react";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import Pagination from "react-js-pagination";
import {
  // useMutation,
  useQuery,
  // useQueryClient
} from "react-query";
import { submit } from "components/utils/proxy";

const TableList = (props) => {
  const { value } = props;
  const user = JSON.parse(localStorage.getItem("user"));
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  const [limit] = React.useState(2);
  const [jumlahData, setJumlahData] = React.useState(1);
  const [search, setSearch] = React.useState("");

  const { isLoading, error, data } = useQuery(
    ["data-prestasi-dashboard", { value, page, search }],
    async () => {
      const token = localStorage.getItem("token");
      axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
      var params = "";
      if (search !== "") {
        params = `&search=${search}`;
      }
      let res = await submit(
        "POST",
        `api/page/home/getPrestasi?limit=${limit}&page=${page}${params}&jenjang=${user.unit.jenjang_unit}`
      );
      setTotalPage(res.total_pages);
      setJumlahData(res.jumlah_data);
      return res.data;
    }
  );
  return (
    <React.Fragment>
      {error && <div>Something went wrong ...</div>}
      <div className="col-md-6"></div>
      <div className="col-md-6">
        <div className="input-group">
          <input
            type="text"
            className="form-control"
            value={search}
            placeholder="Search.."
            onChange={(e) => setSearch(e.target.value)}
          />
          <div className="input-group-btn">
            <button type="button" className="btn btn-success">
              <i className="fa fa-search"></i>
            </button>
          </div>
        </div>
      </div>
      {isLoading ? (
        <table className="table">
          <tbody>
            {Array.from(new Array(2)).map(() => {
              return (
                <tr>
                  <td>
                    <Skeleton width={150} height={120} />
                  </td>
                  <td>
                    <Skeleton count={5} width={300} />
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <table className="table">
          <tbody>
            {data?.length === 0 && (
              <tr className="text-center">
                <td colSpan={6}>Data tidak ditemukan</td>
              </tr>
            )}
            {data?.map((row, index) => {
              return (
                <tr style={{ cursor: "pointer" }} key={index}>
                  <td>
                    <img
                      src={`${process.env.REACT_APP_API_URL}api/image/get/featured/${row.gambar_prestasi}?w=150`}
                      style={{ maxWidth: 150 }}
                    />
                  </td>
                  <td>
                    <strong>{row.judul_prestasi}</strong>
                    <br />
                    <i>
                      Oleh {row.name} Pada{" "}
                      {moment(row.tgl_prestasi).format("DD MMMM YYYY hh:mm:ss")}
                    </i>
                    <br />
                    <br />
                    <br />
                    <a
                      href="#"
                      className="btn btn-warning btn-xs"
                      onClick={(e) => props.editData(e, row)}
                    >
                      <i className="fa fa-edit"></i> Edit
                    </a>
                    <a
                      href="#"
                      className="btn btn-danger btn-xs pull-right"
                      onClick={(e) => props.deleteData(e, row)}
                    >
                      <i className="fa fa-trash"></i> Hapus
                    </a>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      )}
      <div className="no-print">
        <Pagination
          activePage={page}
          itemsCountPerPage={limit}
          totalItemsCount={jumlahData}
          pageRangeDisplayed={5}
          onChange={setPage}
        />
      </div>
    </React.Fragment>
  );
};

export default TableList;
