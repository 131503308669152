import { withRouter } from "react-router-dom";
import React from "react";
import { Navbar, Collapse } from "reactstrap";
import DropdownTwo from "components/Dropdown";
import logo from "assets/img/logo-wide.png";

const Header = (props) => {
  const { history } = props;
  var width = window.innerWidth;
  const [isOpen, setIsOpen] = React.useState(false);

  const [scrolling, setScrolling] = React.useState(false);
  const [scrollTop, setScrollTop] = React.useState(0);

  React.useEffect(() => {
    const onScroll = (e) => {
      setScrollTop(e.target.documentElement.scrollTop);
      setScrolling(e.target.documentElement.scrollTop > scrollTop);
    };
    if (scrollTop > 0) {
      document.getElementById(
        "menu-tambah"
      ).style = `z-index:999;position:fixed;top:0;margin-left:0px;width:${width}px;left:0;`;
      //   style={{zIndex: 999, position: 'fixed', top: 0, marginLeft: 0, width: 1349, left: 0}}
    } else {
      document.getElementById("menu-tambah").style =
        "z-index: auto;position: static;top:auto;";
    }
    window.addEventListener("scroll", onScroll);

    return () => window.removeEventListener("scroll", onScroll);
  }, [scrollTop]);

  return (
    <header id="header" className="header">
      <div className="header-top bg-light sm-text-center style-bordered">
        <div className="container">
          <div className="row">
            <div className="col-md-6">
              <div className="widget">
                <i className="fa fa-clock-o text-theme-colored"></i> Selamat
                datang di Website SEKOLAH PELOPOR DURI
              </div>
            </div>
            <div className="col-md-6">
              <div className="widget">
                <ul className="list-inline pull-right flip sm-pull-none sm-text-center list-bordered">
                  <li className="bg-theme-colored text-white mb-xs-5">
                    <i className="fa fa-phone"></i> Telp{" "}
                    <a className="text-white" href="#">
                      0821-8528-2322
                    </a>
                  </li>
                  <li className="bg-theme-colored3 text-white mb-xs-5">
                    <i className="fa fa-envelope-o"></i>{" "}
                    <a className="text-white" href="#">
                      info@namadomain.com
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Navbar className="header-nav sticky-top" style={{ marginBottom: 0 }}>
        <div
          id="menu-tambah"
          className="header-nav-wrapper bg-white navbar-scrolltofixed scroll-to-fixed-fixed"
        >
          <div className="container">
            <nav
              id="menuzord-right"
              className="menuzord orange no-bg menuzord-responsive"
            >
              <a
                alt="button-caret"
                className="showhide"
                onClick={() => setIsOpen(!isOpen)}
                style={{ display: `${width <= 1028 ? "block" : "none"}` }}
              >
                <em></em>
                <em></em>
                <em></em>
              </a>

              <a
                className="menuzord-brand pull-left flip mb-15"
                onClick={() => history.push(`${process.env.PUBLIC_URL}/`)}
              >
                <img src={logo} alt="logo-sekolah" />
              </a>
              <Collapse
                isOpen={isOpen}
                style={{
                  width: "100%",
                }}
              >
                <ul
                  className="menuzord-menu menuzord-right menuzord-indented scrollable"
                  style={{
                    maxHeight: 400,
                  }}
                >
                  <li>
                    <a
                      onClick={() => history.push(`${process.env.PUBLIC_URL}/`)}
                    >
                      Halaman Utama
                    </a>
                  </li>
                  <DropdownTwo
                    title="Sekolah"
                    history={history}
                    child={[
                      {
                        name: "PAUD",
                        path: `${process.env.PUBLIC_URL}/profil/TK`,
                      },
                      {
                        name: "SD",
                        path: `${process.env.PUBLIC_URL}/profil/SD`,
                      },
                      {
                        name: "SMP",
                        path: `${process.env.PUBLIC_URL}/profil/SMP`,
                      },
                    ]}
                  />
                  <DropdownTwo
                    title="Prestasi"
                    history={history}
                    child={[
                      {
                        name: "PAUD",
                        path: `${process.env.PUBLIC_URL}/prestasi/TK`,
                      },
                      {
                        name: "SD",
                        path: `${process.env.PUBLIC_URL}/prestasi/SD`,
                      },
                      {
                        name: "SMP",
                        path: `${process.env.PUBLIC_URL}/prestasi/SMP`,
                      },
                    ]}
                  />
                  <li>
                    <a
                      onClick={() => history.push(`${process.env.PUBLIC_URL}/fasilitas`)}
                    >
                      Fasilitas
                    </a>
                  </li>
                  <DropdownTwo
                    title="Event"
                    history={history}
                    child={[
                      {
                        name: "PAUD",
                        path: `${process.env.PUBLIC_URL}/event/TK`,
                      },
                      {
                        name: "SD",
                        path: `${process.env.PUBLIC_URL}/event/SD`,
                      },
                      {
                        name: "SMP",
                        path: `${process.env.PUBLIC_URL}/event/SMP`,
                      },
                    ]}
                  />
                  <DropdownTwo
                    title="Extrakulikuler"
                    history={history}
                    child={[
                      {
                        name: "PAUD",
                        path: `${process.env.PUBLIC_URL}/extrakulikuler/TK`,
                      },
                      {
                        name: "SD",
                        path: `${process.env.PUBLIC_URL}/extrakulikuler/SD`,
                      },
                      {
                        name: "SMP",
                        path: `${process.env.PUBLIC_URL}/extrakulikuler/SMP`,
                      },
                    ]}
                  />
                  <DropdownTwo
                    title="Pembelajaran"
                    history={history}
                    child={[
                      {
                        name: "Guru",
                        path: `${process.env.PUBLIC_URL}/guru`,
                      },
                      {
                        name: "Download Materi",
                        path: `${process.env.PUBLIC_URL}/download-materi`,
                      },
                      {
                        name: "Kelas Online",
                        path: `${process.env.PUBLIC_URL}/kelas-online`,
                      },
                    ]}
                  />
                  <DropdownTwo
                    title="Siswa"
                    history={history}
                    child={[
                      {
                        name: "Jadwal",
                        path: `${process.env.PUBLIC_URL}/jadwal-siswa`,
                      },
                    ]}
                  />
                  <li className="scrollable-fix"></li>
                </ul>
              </Collapse>
              {width >= 1028 && (
                <ul
                  className="menuzord-menu menuzord-right menuzord-indented scrollable"
                  style={{
                    maxHeight: 400,
                  }}
                >
                  <li>
                    <a
                      onClick={() => history.push(`${process.env.PUBLIC_URL}/`)}
                    >
                      Halaman Utama
                    </a>
                  </li>
                  <DropdownTwo
                    title="Sekolah"
                    history={history}
                    child={[
                      {
                        name: "PAUD",
                        path: `${process.env.PUBLIC_URL}/profil/TK`,
                      },
                      {
                        name: "SD",
                        path: `${process.env.PUBLIC_URL}/profil/SD`,
                      },
                      {
                        name: "SMP",
                        path: `${process.env.PUBLIC_URL}/profil/SMP`,
                      },
                    ]}
                  />
                  <DropdownTwo
                    title="Prestasi"
                    history={history}
                    child={[
                      {
                        name: "PAUD",
                        path: `${process.env.PUBLIC_URL}/prestasi/TK`,
                      },
                      {
                        name: "SD",
                        path: `${process.env.PUBLIC_URL}/prestasi/SD`,
                      },
                      {
                        name: "SMP",
                        path: `${process.env.PUBLIC_URL}/prestasi/SMP`,
                      },
                    ]}
                  />
                  <li>
                    <a
                      onClick={() => history.push(`${process.env.PUBLIC_URL}/fasilitas`)}
                    >
                      Fasilitas
                    </a>
                  </li>
                  <DropdownTwo
                    title="Event"
                    history={history}
                    child={[
                      {
                        name: "PAUD",
                        path: `${process.env.PUBLIC_URL}/event/TK`,
                      },
                      {
                        name: "SD",
                        path: `${process.env.PUBLIC_URL}/event/SD`,
                      },
                      {
                        name: "SMP",
                        path: `${process.env.PUBLIC_URL}/event/SMP`,
                      },
                    ]}
                  />
                  <DropdownTwo
                    title="Extrakulikuler"
                    history={history}
                    child={[
                      {
                        name: "PAUD",
                        path: `${process.env.PUBLIC_URL}/extrakulikuler/TK`,
                      },
                      {
                        name: "SD",
                        path: `${process.env.PUBLIC_URL}/extrakulikuler/SD`,
                      },
                      {
                        name: "SMP",
                        path: `${process.env.PUBLIC_URL}/extrakulikuler/SMP`,
                      },
                    ]}
                  />
                  <DropdownTwo
                    title="Pembelajaran"
                    history={history}
                    child={[
                      {
                        name: "Guru",
                        path: `${process.env.PUBLIC_URL}/guru`,
                      },
                      {
                        name: "Download Materi",
                        path: `${process.env.PUBLIC_URL}/download-materi`,
                      },
                      {
                        name: "Kelas Online",
                        path: `${process.env.PUBLIC_URL}/kelas-online`,
                      },
                    ]}
                  />
                  <DropdownTwo
                    title="Siswa"
                    history={history}
                    child={[
                      {
                        name: "Jadwal",
                        path: `${process.env.PUBLIC_URL}/jadwal-siswa`,
                      },
                    ]}
                  />
                  <li className="scrollable-fix"></li>
                </ul>
              )}
            </nav>
          </div>
        </div>
        {/* <div
          style={{ display: "block", width: 1349, height: 72, float: "none" }}
        ></div> */}
      </Navbar>
    </header>
  );
};

export default withRouter(Header);
