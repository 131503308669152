import React from "react";

const DropdownTwo = (props) => {
  const { title, child, history } = props;
  const [open, setOpen] = React.useState(false);
  return (
    <li onMouseLeave={() => setOpen(false)}>
      <a onMouseEnter={() => setOpen(true)}>
        {title}
        <span className="indicator">
          <i className="fa fa-angle-down"></i>
        </span>
      </a>
      {child && (
        <ul
          className="dropdown"
          style={{ right: "auto", display: `${open ? "block" : "none"}` }}
        >
          {child?.map((value, key) => (
            <li key={key}>
              <a onClick={() => history.push(value.path)}>{value.name}</a>
            </li>
          ))}
        </ul>
      )}
    </li>
  );
};

export default DropdownTwo;
