const Pagination = (props) => {
  const { currentPage, totalPage, limit, setPage } = props;
  return (
    <nav>
      <ul className="pagination theme-colored">
        <li className={currentPage <= 1 ? "disabled" : ""}>
          <a
            href="#"
            onClick={() =>
              currentPage <= 1 ? false : setPage(currentPage - 1)
            }
          >
            <b>{"<<"}</b>
          </a>
        </li>
        {Array.from(new Array(totalPage)).map((number, index) => {
          return (
            <li
              key={index}
              className={currentPage === index + 1 ? "active" : ""}
            >
              <a href="#" onClick={() => setPage(index + 1)}>
                <b>{index + 1}</b>
              </a>
            </li>
          );
        })}
        <li className={currentPage === totalPage ? "disabled" : ""}>
          <a
            href="#"
            onClick={() =>
              currentPage === totalPage ? false : setPage(currentPage + 1)
            }
          >
            <b>{">>"}</b>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
