import React from "react";
import { Helmet } from "react-helmet";
import Layout from "components/PublicLayout";
import Banner from "components/Section/banner";
import Artikel from "components/Artikel";
import EventList from "components/Event";
import BigPrestasi from "components/BigPrestasi";
import SideMenu from "components/Section/sidemenu";
import Pagination from "components/Pagination";

const Prestasi = (props) => {
  const { history, match } = props;
  const {params} = match;
  const [page, setPage] = React.useState(1);
  const [totalPage, setTotalPage] = React.useState(1);
  return (
    <Layout>
      <Helmet titleTemplate="Irene - Auth" defaultTitle="Prestasi">
        <meta name="description" content="Prestasi of Irene Dashboard" />
      </Helmet>
      <div className="main-content">
        <Banner title="Prestasi" history={history} />
        <section>
          <div className="container mt-30 mb-30 pt-30 pb-30">
            <div className="row">
              <div className="col-md-9">
                <div className="Event-posts">
                  <div className="col-md-12">
                    <div className="row list-dashed">
                      <BigPrestasi
                        history={history}
                        page={page}
                        jenjang={params.jenjang}
                        totalPage={totalPage}
                        totalPageSet={setTotalPage}
                      />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <Pagination
                      currentPage={page}
                      totalPage={totalPage}
                      setPage={setPage}
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-3">
                <div className="sidebar sidebar-right mt-sm-30">
                  <div className="widget">
                    <h5 className="widget-title line-bottom">Informasi</h5>
                    <div className="categories">
                      <ul className="list list-border angle-double-right">
                      <li>
                          <span
                            onClick={() => history.push(`/blog/${params.jenjang}`)}
                            className="cursor-pointer"
                          >
                            Artikel &amp; Pengumuman
                          </span>
                        </li>
                        <li>
                          <span
                            onClick={() => history.push(`/event/${params.jenjang}`)}
                            className="cursor-pointer"
                          >
                            Event
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>

                  <div className="widget">
                    <h5 className="widget-title line-bottom">
                      Artikel Terbaru
                    </h5>
                    <Artikel history={history} jenjang={params.jenjang}/>
                  </div>
                  <div className="widget">
                    <h5 className="widget-title line-bottom">Event Terbaru</h5>
                    <EventList history={history} jenjang={params.jenjang}/>
                  </div>
                  <div className="widget">
                    <h5 className="widget-title line-bottom">Menu Utama</h5>
                    <div className="categories">
                      <SideMenu history={history} jenjang={params.jenjang}/>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </Layout>
  );
};

export default Prestasi;
