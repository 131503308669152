const SideMenu = (props) => {
  const { history , jenjang} = props;
  return (
    <ul className="list list-border angle-double-right">
      <li>
        <a
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/profil/${jenjang}`);
          }}
        >
          Profil
        </a>
      </li>
      <li>
        <a
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/visi-misi/${jenjang}`);
          }}
        >
          Visi Misi
        </a>
      </li>
      <li>
        <a
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/sejarah/${jenjang}`);
          }}
        >
          Sejarah
        </a>
      </li>
      <li>
        <a
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/blog/${jenjang}`);
          }}
        >
          Artikel &amp; Pengumuman
        </a>
      </li>
      <li>
        <a
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/event/${jenjang}`);
          }}
        >
          Event
        </a>
      </li>
      <li>
        <a
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/prestasi/${jenjang}`);
          }}
        >
          Prestasi
        </a>
      </li>
      <li>
        <a
          onClick={() => {
            history.push(`${process.env.PUBLIC_URL}/extrakulikuler/${jenjang}`);
          }}
        >
          Extrakulikuler
        </a>
      </li>
    </ul>
  );
};

export default SideMenu;
