import React from "react";
import ApiService from "services/ApiService";
import axios from "axios";
import Skeleton from "react-loading-skeleton";
import moment from "moment";
import {
  // useMutation,
  useQuery,
  // useQueryClient
} from "react-query";

const BigFasilitas = (props) => {
  const { history, page, totalPageSet, jenjang } = props;

  const { isLoading, error, data } = useQuery(
    ["big-fasilitas-list", { page, jenjang }],
    async () => {
      let res = await axios(
        `${process.env.REACT_APP_API_URL}api/page/home/getFasilitas?limit=10&page=${page}&jenjang=${jenjang}`
      );
      res = res.data;
      totalPageSet(res.total_pages);
      return res.data;
    }
  );

  return (
    <React.Fragment>
      {error && <div>Something went wrong ...</div>}
      {isLoading ? (
        <React.Fragment>
          <div className="row">
            {Array.from(new Array(4)).map((value) => (
              <div className="col-md-4">
                <article className="post clearfix mb-30 pb-30" key={value}>
                  <div className="entry-header">
                    <div className="post-thumb thumb">
                      <Skeleton height={200} />
                    </div>
                  </div>
                  <div className="entry-content border-1px p-20 pr-10">
                    <div className="entry-meta media mt-0 no-bg no-border">
                      <div className="media-body pl-15">
                        <div className="Blog-content flip">
                          <h4 className="entry-title text-uppercase m-0 mt-5 cursor-pointer">
                            <Skeleton width={100} />
                          </h4>
                        </div>
                      </div>
                    </div>
                    <Skeleton count={3} />
                    <button className="btn btn-read-more" disabled>
                      Loading
                    </button>
                    <div className="clearfix"></div>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </React.Fragment>
      ) : (
        <React.Fragment>
          <div className="row">
            {data?.map((value, key) => (
              <div className="col-md-4">
                <article className="post clearfix mb-30 pb-30" key={key}>
                  <div className="entry-header">
                    <div className="post-thumb thumb">
                      <img
                        src={`${process.env.REACT_APP_API_URL}api/image/get/featured/${value.gambar_fasilitas}`}
                        alt={value.judul_fasilitas}
                        style={{height: 150}}
                        className="img-responsive img-fullwidth"
                      />
                    </div>
                  </div>
                  <div className="entry-content border-1px p-20 pr-10">
                    <div className="entry-meta media mt-0 no-bg no-border">
                      <div className="media-body pl-15">
                        <div className="Blog-content flip" style={{textAlign:'center'}}>
                          <h4
                            className="entry-title text-uppercase m-0 mt-5 cursor-pointer"
                            onClick={() =>
                              history.push(
                                `${process.env.PUBLIC_URL}/detail-Fasilitas/${value.url_fasilitas}`
                              )
                            }
                          >
                            {value.judul_fasilitas}
                          </h4>
                        </div>
                      </div>
                    </div>
                    <br />
                    <div className="clearfix"></div>
                  </div>
                </article>
              </div>
            ))}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default BigFasilitas;
