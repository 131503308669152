import React from "react";
import { Content } from "adminlte-2-react";
import {
  // useMutation,
  useQuery,
  // useQueryClient
} from "react-query";
import { submit } from "components/utils/proxy";
import moment from "moment";
import DataPeriodik from "./dataPeriodik";
import DataPeriodikDua from "./dataPeriodikDua";
import DataKelas from "./dataKelas";
import SelectTwo from "components/SelectTwo";
import { confirm } from "components/ConfirmDialog";
import { useForm } from "react-hook-form";

const Kelas = (props) => {
  const { data } = useQuery(["data-kelas-concat"], async () => {
    let res = await submit("POST", `api/page/find/getJadwalKelasList`);
    return res.data;
  });
  return (
    <SelectTwo
      className="basic-single"
      classNamePrefix="Pilih Kelas"
      isClearable={true}
      isSearchable={true}
      name="kelas"
      ref={props.register}
      value={props.value}
      onChange={props.onChange}
      options={data}
    />
  );
};

const DetailSiswa = (props) => {
  const { history, match } = props;
  const { params } = match;
  const { slug } = params;
  const tahunstart = moment(new Date()).format("Y");
  const start = parseInt(tahunstart) + 1;
  var i;
  const tahunend = 2019;
  let tahunberikutnya = 0;
  let item = [];

  for (i = start; i >= tahunend; i--) {
    tahunberikutnya = i + 1;
    item.push(`${i}/${tahunberikutnya}`);
  }

  const [kelas, setKelas] = React.useState(null);
  const [tahunAjaran, setTahunAjaran] = React.useState("");
  let id = parseInt(slug.split("-").pop());

  let { handleSubmit, errors, register } = useForm();
  let {
    register: register2,
    errors: errors2,
    handleSubmit: handleSubmit2,
  } = useForm();
  let {
    register: register3,
    errors: errors3,
    handleSubmit: handleSubmit3,
  } = useForm();

  const { data } = useQuery(["detail-siswa", { slug }], async () => {
    let res = await submit("POST", `api/page/siswa/getById`, {
      id: id,
    });
    return res.data[0];
  });

  const doCreatePeriodik = async (data, event) => {
    event.preventDefault();
    const params = {
      jenis: data.txtJenis,
      nama: data.txtNama,
      penyelenggara: data.txtPenyelenggara,
      tahun: data.txtTahun,
      Tingkat: data.txtTingkat,
    };
    let res = await submit(
      "POST",
      `api/page/siswa/createPeriodik?id_siswa=${id}`,
      params
    );
    if (res.success_message) {
      window.location.reload();
    }
  };

  const doCreatePeriodikDua = async (data, event) => {
    event.preventDefault();
    const params = {
      jenis: data.txtJenis,
      penyelenggara: data.txtPenyelenggara,
      tahun1: data.txtTahun1,
      tahun2: data.txtTahun2,
    };
    let res = await submit(
      "POST",
      `api/page/siswa/createPeriodikDua?id_siswa=${id}`,
      params
    );
    if (res.success_message) {
      window.location.reload();
    }
  };

  const doCreateKelas = async (data, event) => {
    event.preventDefault();
    const params = {
      kelas: kelas?.value,
      tahunAjaran: data.tahunAjaran,
    };
    let res = await submit(
      "POST",
      `api/page/siswa/createKelas?id_siswa=${id}`,
      params
    );
    if (res.success_message) {
      window.location.reload();
    }
  };

  const doDeletePeriodik = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_periodik: data.id_periodik,
      };
      let res = await submit("POST", `api/page/siswa/deletePeriodik`, params);
      if (res.success_message) {
        window.location.reload();
      }
    });
  };

  const doDeletePeriodikDua = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_periodik: data.id_periodik,
      };
      let res = await submit(
        "POST",
        `api/page/siswa/deletePeriodikDua`,
        params
      );
      if (res.success_message) {
        window.location.reload();
      }
    });
  };

  const doDeleteKelas = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        id_detail: data.id_detail,
      };
      let res = await submit("POST", `api/page/siswa/deleteKelas`, params);
      if (res.success_message) {
        window.location.reload();
      }
    });
  };
  return (
    <Content
      title="Detail SISWA"
      subTitle="SEKOLAH PELOPOR"
      homeRoute={`${process.env.PUBLIC_URL}/dashboard/direktory-siswa`}
      browserTitle="Direktory SISWA"
    >
      <section className="content invoice">
        <div className="row">
          <div className="col-xs-12">
            <h2 className="page-header" style={{ textAlign: "center" }}>
              <small>
                <strong>FORMULIR PENDAFTARAN SISWA</strong>
              </small>
              <small>
                {moment(data?.tgl_pendaftaran).format("DD MMMM YYYY")}
              </small>
            </h2>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>DATA PENDAFTARAN</h4>
            </div>
          </div>
          <div className="col-xs-4">
            <table className="table">
              <tbody>
                <tr>
                  <th>Tingkat</th>
                  <td style={{ width: "50%" }}>: {data?.tingkat}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-4">
            <table className="table">
              <tbody>
                <tr>
                  <th>Program</th>
                  <td style={{ width: "50%" }}>: {data?.nama_program}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-4">
            <table className="table">
              <tbody>
                <tr>
                  <th>No REG</th>
                  <td style={{ width: "50%" }}>: {data?.no_reg} </td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>IDENTITAS PESERTA DIDIK</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>Nama Lengkap</th>
                  <td style={{ width: "50%" }}>: {data?.nama_lengkap}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Jenis Kelamin</th>
                  <td style={{ width: "50%" }}>: {data?.jenis_kelamin}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NISN</th>
                  <td style={{ width: "50%" }}>: {data?.nisn}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NIS</th>
                  <td style={{ width: "50%" }}>: {data?.nis}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Nomor Seri IJAZAH</th>
                  <td style={{ width: "50%" }}>: {data?.seri_ijasah}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Nomor Seri SKHUN</th>
                  <td style={{ width: "50%" }}>: {data?.seri_skhun}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Nomor Ujian Nasional</th>
                  <td style={{ width: "50%" }}>: {data?.no_unas}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>No. Induk Kependudukan</th>
                  <td style={{ width: "50%" }}>: {data?.nik}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>NPSN Sekolah Asal</th>
                  <td style={{ width: "50%" }}>: {data?.npsn}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Nama Sekolah Asal</th>
                  <td style={{ width: "50%" }}>: {data?.sekolah_asal}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Tempat Lahir</th>
                  <td style={{ width: "50%" }}>: {data?.tempat_lahir}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Tanggal Lahir</th>
                  <td style={{ width: "50%" }}>
                    : {moment(data?.tgl_lahir).format("DD MMMM YYYY")}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Agama</th>
                  <td style={{ width: "50%" }}>: {data?.agama}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Berkebutuhan Khusus</th>
                  <td style={{ width: "50%" }}>: {data?.kebutuhan_khusus}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Alamat</th>
                  <td style={{ width: "50%" }}>: {data?.alamat}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Dusun</th>
                  <td style={{ width: "50%" }}>
                    : {data?.dusun} RT : {data?.rt} / RW : {data?.rw}{" "}
                  </td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Keluarahan / Desa</th>
                  <td style={{ width: "50%" }}>: {data?.kelurahan}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Kode Pos</th>
                  <td style={{ width: "50%" }}>: {data?.kode_pos}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Kecamatan</th>
                  <td style={{ width: "50%" }}>: {data?.kecamatan}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th style={{ width: "50%" }}>Kabupaten</th>
                  <td style={{ width: "50%" }}>: {data?.kabupaten}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Provinsi</th>
                  <td style={{ width: "50%" }}>: {data?.provinsi}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Alat Transportasi</th>
                  <td style={{ width: "50%" }}>: {data?.transportasi}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Jenis Tinggal</th>
                  <td style={{ width: "50%" }}>: {data?.jenis_tinggal}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>No Telepon Rumah</th>
                  <td style={{ width: "50%" }}>: {data?.no_telp}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>No. Hp</th>
                  <td style={{ width: "50%" }}>: {data?.no_handphone}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Email Pribadi</th>
                  <td style={{ width: "50%" }}>: {data?.email}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>No. KKS</th>
                  <td style={{ width: "50%" }}>: {data?.no_kks}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Apakah Penerima KPS / PKH</th>
                  <td style={{ width: "50%" }}>: {data?.status_kps}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>No. KPS</th>
                  <td style={{ width: "50%" }}>: {data?.no_ksp}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>
                    Usulan Dari Sekolah Layak PIP
                  </th>
                  <td style={{ width: "50%" }}>: {data?.status_pip}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Alasan Layak</th>
                  <td style={{ width: "50%" }}>: {data?.usulan_pip}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Penerima KIP</th>
                  <td style={{ width: "50%" }}>: {data?.penerima_kip}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>No. KIP</th>
                  <td style={{ width: "50%" }}>: {data?.penerima_kip}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Nama Tertera di KIP</th>
                  <td style={{ width: "50%" }}>: {data?.nama_kip}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Alasan Menolak KIP</th>
                  <td style={{ width: "50%" }}>: {data?.keterangan}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>No Registrasi Akta Lahir</th>
                  <td style={{ width: "50%" }}>: {data?.rek_akta}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Lintang</th>
                  <td style={{ width: "50%" }}>: {data?.lintang}</td>
                </tr>
                <tr>
                  <th style={{ width: "50%" }}>Bujur</th>
                  <td style={{ width: "50%" }}>: {data?.bujur}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>

        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>DATA AYAH KANDUNG</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>Nama Ayah</th>
                  <td style={{ width: "50%" }}>: {data?.nama_ayah}</td>
                </tr>
                <tr>
                  <th>Tahun Lahir</th>
                  <td style={{ width: "50%" }}>: {data?.tahun_lahir_ayah}</td>
                </tr>
                <tr>
                  <th>Berkebutuhan Khusus</th>
                  <td style={{ width: "50%" }}>
                    : {data?.kebutuhan_khusus_ayah}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>Pekerjaan</th>
                  <td style={{ width: "50%" }}>: {data?.pekerjaan_ayah}</td>
                </tr>
                <tr>
                  <th>Pendidikan</th>
                  <td style={{ width: "50%" }}>: {data?.pendidikan_ayah}</td>
                </tr>
                <tr>
                  <th>Penghasilan Bulanan</th>
                  <td style={{ width: "50%" }}>: {data?.penghasilan_ayah}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>DATA IBU KANDUNG</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>Nama Ibu</th>
                  <td style={{ width: "50%" }}>: {data?.nama_ibu}</td>
                </tr>
                <tr>
                  <th>Tahun Lahir</th>
                  <td style={{ width: "50%" }}>: {data?.tahun_lahir_ibu}</td>
                </tr>
                <tr>
                  <th>Berkebutuhan Khusus</th>
                  <td style={{ width: "50%" }}>
                    : {data?.kebutuhan_khusus_ibu}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>Pekerjaan</th>
                  <td style={{ width: "50%" }}>: {data?.pekerjaan_ibu}</td>
                </tr>
                <tr>
                  <th>Pendidikan</th>
                  <td style={{ width: "50%" }}>: {data?.pendidikan_ibu}</td>
                </tr>
                <tr>
                  <th>Penghasilan Bulanan</th>
                  <td style={{ width: "50%" }}>: {data?.penghasilan_ibu}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>DATA WALI</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>Nama Wali</th>
                  <td style={{ width: "50%" }}>: {data?.nama_wali}</td>
                </tr>
                <tr>
                  <th>Tahun Lahir</th>
                  <td style={{ width: "50%" }}>: {data?.tahun_lahir_wali}</td>
                </tr>
                <tr>
                  <th>Berkebutuhan Khusus</th>
                  <td style={{ width: "50%" }}>
                    : {data?.kebutuhan_khusus_wali}
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>Pekerjaan</th>
                  <td style={{ width: "50%" }}>: {data?.pekerjaan_wali}</td>
                </tr>
                <tr>
                  <th>Pendidikan</th>
                  <td style={{ width: "50%" }}>: {data?.pendidikan_wali} </td>
                </tr>
                <tr>
                  <th>Penghasilan Bulanan</th>
                  <td style={{ width: "50%" }}>:{data?.penghasilan_wali}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 30px 5px 15px" }}
            >
              <h4 style={{ marginBottom: 0 }}>Data Periodik</h4>
            </div>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>Tinggi Badan</th>
                  <td style={{ width: "50%" }}>: {data?.tinggi_badan}</td>
                </tr>
                <tr>
                  <th>Berat Badan</th>
                  <td style={{ width: "50%" }}>: {data?.berat_badan}</td>
                </tr>
                <tr>
                  <th>Jumlah Saudara</th>
                  <td style={{ width: "50%" }}>: {data?.jumlah_saudara}</td>
                </tr>
              </tbody>
            </table>
          </div>
          <div className="col-xs-6">
            <table className="table">
              <tbody>
                <tr>
                  <th>Jarak Tempat Tinggal Ke Sekolah</th>
                  <td style={{ width: "50%" }}>: {data?.jarak_rumah}</td>
                </tr>
                <tr>
                  <th>Waktu Tempuh Berangkat Ke Sekolah</th>
                  <td style={{ width: "50%" }}>: {data?.waktu_tempuh}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahPeriodik1"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Data Periodik (Wajib Diisi)</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahPeriodik1"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit(doCreatePeriodik)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Data Periodik
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="box-body pad">
                            <label>Jenis Prestasi</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tingkat</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtTingkat"
                              className="form-control"
                            />
                          </div>
                        </div>

                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Nama Prestasi</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtNama"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtTahun"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Penyelenggara</label>
                            <input
                              type="text"
                              ref={register()}
                              name="txtPenyelenggara"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-periodik1"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Priodik
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <DataPeriodik idSiswa={id} deleteData={doDeletePeriodik} />
          </div>
        </div>
        <div className="row">
          <div className="col-xs-12">
            <div
              className="callout callout-info"
              style={{ padding: "5px 5px 5px 15px" }}
            >
              <button
                className="btn btn-success btn-xs pull-right no-print"
                data-toggle="modal"
                data-target="#myModalTambahPeriodik2"
              >
                <i className="fa fa-plus"></i> Tambah
              </button>
              <h4 style={{ marginBottom: 0 }}>Data Periodik (Wajib Diisi)</h4>
            </div>
          </div>
          <div
            className="modal fade"
            id="myModalTambahPeriodik2"
            tabindex="-1"
            role="dialog"
            aria-labelledby="myModalLabel"
          >
            <div className="modal-dialog" role="document">
              <form
                onSubmit={handleSubmit2(doCreatePeriodikDua)}
                encType="multipart/form-data"
              >
                <div className="modal-content">
                  <div className="modal-header">
                    <button
                      type="button"
                      className="close"
                      data-dismiss="modal"
                      aria-label="Close"
                    >
                      <span aria-hidden="true">×</span>
                    </button>
                    <h4 className="modal-title" id="myModalLabel">
                      <i className="fa fa-plus" style={{ marginRight: 5 }}></i>{" "}
                      Tambah Data Periodik
                    </h4>
                  </div>
                  <div className="modal-body">
                    <div className="box box-default">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Jenis</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtJenis"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Penyelenggara</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtPenyelenggara"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun Mulai</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtTahun1"
                              className="form-control"
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="box-body pad">
                            <label>Tahun Selesai</label>
                            <input
                              type="text"
                              ref={register2()}
                              name="txtTahun2"
                              className="form-control"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button
                      type="button"
                      className="btn btn-default"
                      data-dismiss="modal"
                    >
                      <i className="fa fa-times" style={{ marginRight: 5 }}></i>{" "}
                      Batal
                    </button>
                    <button
                      type="submit"
                      name="tambah-periodik2"
                      className="btn btn-success"
                    >
                      <i
                        className="fa fa-location-arrow "
                        style={{ marginRight: 5 }}
                      ></i>{" "}
                      Tambah Data Priodik
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <div className="col-xs-12">
            <DataPeriodikDua idSiswa={id} deleteData={doDeletePeriodikDua} />
          </div>
          <div className="col-xs-6">
            <label>
              <input type="checkbox" /> Yang bertanda tangan Orang Tua/Wali atau
              Siswa
            </label>
            <br />
            <label>
              <input type="checkbox" /> Bertanggung jawab secara hukum terhadap
              kebenaran data yang tercantum.
            </label>
          </div>
          <div className="col-xs-6">
            <p className="margin">
              <strong>
                ............, ...................................
                <br />
                Responden,
              </strong>
              <br />
              <br />
              <br />
              <br />
              <br />
              .....................................................
              <br />
              Tanda Tangan &amp; Nama Terang
            </p>
          </div>
        </div>
      </section>
    </Content>
  );
};

export default DetailSiswa;
