import React from "react";
import "./style.css";
import logo from "assets/img/logo-wide.png";

function Loader() {
  document.getElementById("root").className = "layoutBody";
  return (
    <div className="full-page-loader">
      <img src={logo} alt="logo" />
    </div>
  );
}

export default Loader;
