import React from "react";
import { Helmet } from "react-helmet";
import SelectTwo from "components/SelectTwo";
import axios from "axios";
import { useForm } from "react-hook-form";
import { Content, Row, Col, Box, Button, Alert } from "adminlte-2-react";
import TableList from "./components/TableList";
import { confirm } from "components/ConfirmDialog";
import InputTextareaEditor from "components/InputTextareaEditor";

const DataExtrakulikuler = () => {
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));
  axios.defaults.headers.common["Authorization"] = `Bearer ${token}`;
  const modules = {
    toolbar: [
      ["bold", "italic", "underline"],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link"],
    ],
  };

  const formats = ["bold", "italic", "underline", "list", "bullet", "link"];

  const [id, setId] = React.useState(null);
  const [judulArtikel, setJudulArtikel] = React.useState("");
  const [imgValue, setImgValue] = React.useState(null);
  const [value, setValue] = React.useState("");
  const [isEdit, setIsEdit] = React.useState(false);

  const [componentDidUpdate, setComponentDidUpdate] = React.useState(false);
  const [success, setSuccess] = React.useState(null);
  const imgRef = React.useRef();

  const handleEdit = (e, data) => {
    if (
      data.judul_extrakulikuler !== judulArtikel ||
      data.isi_extrakulikuler !== value ||
      data.gambar_extrakulikuler !== imgValue
    ) {
      setId(data.id_extrakulikuler);
      setJudulArtikel(data.judul_extrakulikuler);
      setImgValue(data?.gambar_extrakulikuler);
      setValue(data.isi_extrakulikuler);
      setIsEdit(true);
    } else {
      setId(null);
      setJudulArtikel("");
      setImgValue(null);
      setValue("");
      setIsEdit(false);
    }
  };
  const handleImage = (event) => {
    if (event.target.files.length > 0) {
      const file = event.target;
      doUploadImage(file.files[0]);
    }
  };
  const doUploadImage = async (file) => {
    let form = new FormData();
    form.append("image", file);
    try {
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/image/uploadImage`,
        form
      );
      res = res.data;
      if (res) {
        setImgValue(res.name);
      }
    } catch (error) {
      setImgValue(error?.response?.data?.name ?? '');
    }
  };
  const doCreate = async (event) => {
    event.preventDefault();
    // const uploadData = await doUploadImage();
    let res = await axios.post(
      `${process.env.REACT_APP_API_URL}api/page/home/insertExtrakulikuler`,
      {
        judul_extrakulikuler: judulArtikel,
        gambar_extrakulikuler: imgValue,
        isi_extrakulikuler: value,
        id_user: user?.idUser,
        jenjang: user?.unit.jenjang_unit,
        url_extrakulikuler: judulArtikel.replace(/ /g, "_")
      }
    );
    res = res.data;
    if (res.success_message) {
      setSuccess(res.message);
      setComponentDidUpdate(!componentDidUpdate);
      setId(null);
      // imgRef.current.value = "";
      setJudulArtikel("");
      setImgValue(null);
      setValue("");
      setIsEdit(false);
    }
  };

  const doEdit = async (event) => {
    event.preventDefault();
    const params = {
      judul_extrakulikuler: judulArtikel,
      gambar_extrakulikuler: imgValue,
      isi_extrakulikuler: value,
      id_user: user?.idUser,
      jenjang: user?.unit.jenjang_unit,
      id_extrakulikuler: id,
      url_extrakulikuler: judulArtikel.replace(/ /g, "_")
    };
    let res = await axios.put(
      `${process.env.REACT_APP_API_URL}api/page/home/updateExtrakulikuler/${id}`,
      params
    );
    res = res.data;
    if (res.success_message) {
      setSuccess(res.message);
      setComponentDidUpdate(!componentDidUpdate);
      // imgRef.current.value = "";
      setId(null);
      setJudulArtikel("");
      setImgValue(null);
      setValue("");
      setIsEdit(false);
    }
  };

  const doDelete = (event, data) => {
    event.preventDefault();
    confirm("Delete", "Yakin Ingin Menghapus ?").then(async (onProcess) => {
      const params = {
        judul_extrakulikuler: data.judul_extrakulikuler,
        gambar_extrakulikuler: data.gambar_extrakulikuler,
        isi_extrakulikuler: data.isi_extrakulikuler,
        jenjang: user?.unit.jenjang_unit,
        id_user: user?.idUser,
        id_extrakulikuler: data.id_extrakulikuler,
      };
      let res = await axios.post(
        `${process.env.REACT_APP_API_URL}api/page/home/deleteExtrakulikuler`,
        params
      );
      res = res.data;
      if (res.success_message) {
        setSuccess(res.message);
        setComponentDidUpdate(!componentDidUpdate);
        setId(null);
        setJudulArtikel("");
        setImgValue(null);
        setValue("");
        setIsEdit(false);
      }
    });
  };
  const handleChange = (e) => {
    setValue(e.target);
  };
  const doCancel = () => {
    setId(null);
    setJudulArtikel("");
    setImgValue(null);
    setValue("");
    setIsEdit(false);
  };
  return (
    <React.Fragment>
      <Helmet titleTemplate="Irene - Auth" defaultTitle="Data Extrakulikuler">
        <meta name="description" content="Data Extrakulikuler of Irene Dashboard" />
      </Helmet>
      <Content
        title="Data Extrakulikuler"
        subTitle="SEKOLAH PELOPOR"
        homeRoute={`${process.env.PUBLIC_URL}/dashboard/home`}
        browserTitle="Data Extrakulikuler"
      >
        <Row>
          <Col md={12}>
            {success && (
              <Alert
                closable
                type="success"
                title={success}
                onDismiss={() => setSuccess(null)}
                icon="fa-info"
              />
            )}
          </Col>
          <Col xs={isEdit ? 12 : 5}>
            <form
              onSubmit={(e) => (isEdit ? doEdit(e) : doCreate(e))}
              enctype="multipart/form-data"
            >
              <Box
                header={
                  <span>
                    <i className="fa fa-newspaper-o"></i>
                    {!isEdit ? " Tambah" : "Edit"} Data Extrakulikuler
                  </span>
                }
                type="primary"
                footer={
                  <React.Fragment>
                    <button
                      type="submit"
                      name="tambah-jenjang"
                      disabled={
                        imgValue === null || judulArtikel === "" || value === ""
                      }
                      className="btn btn-success"
                    >
                      {!isEdit ? (
                        <span>
                          <i className="fa fa-plus"></i> Tambah Data Extrakulikuler
                        </span>
                      ) : (
                        <span>
                          <i className="fa fa-edit"></i> Edit Data Extrakulikuler
                        </span>
                      )}
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    {isEdit && (
                      <button
                        type="button"
                        className="btn btn-default"
                        onClick={doCancel}
                      >
                        Batal
                      </button>
                    )}
                  </React.Fragment>
                }
              >
                <div className="row">
                  <div className="col-md-6">
                    <div className="box-body pad">
                      <label>Judul Extrakulikuler</label>
                      <input
                        type="text"
                        name="txtNama"
                        className="form-control"
                        value={judulArtikel}
                        onChange={(e) => setJudulArtikel(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="box-body pad">
                      <label>
                        Gambar<small> *(1920 x 766 px)</small>
                      </label>
                      {imgValue ? (
                        <div>
                          <img
                            src={`${process.env.REACT_APP_API_URL}api/image/get/featured/${imgValue}?w=150`}
                            style={{ maxWidth: 150 }}
                          />
                          <br />
                          <a href="#" onClick={() => setImgValue(null)}>
                            Ubah Gambar
                          </a>
                        </div>
                      ) : (
                        <input
                          type="file"
                          ref={imgRef}
                          className="form-control"
                          onChange={handleImage}
                        />
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <InputTextareaEditor
                      id="description"
                      name="description"
                      value={value}
                      modules={modules}
                      formats={formats}
                      onChange={(e) => handleChange({ target: e })}
                      showToolBar={true}
                    />
                  </div>
                </div>
              </Box>
            </form>
          </Col>
          <Col xs={isEdit ? 12 : 7}>
            <Box
              header={
                <span>
                  <i className="fa fa-newspaper-o"></i> Data Extrakulikuler
                </span>
              }
              type="primary"
              closable
              collapsable
            >
              <TableList
                value={componentDidUpdate}
                editData={handleEdit}
                deleteData={doDelete}
              />
            </Box>
          </Col>
        </Row>
      </Content>
    </React.Fragment>
  );
};

export default DataExtrakulikuler;
