import TopBarProgress from "react-topbar-progress-indicator";

TopBarProgress.config({
  barColors: {
    0: "#ffffff",
    "1.0": "#ffffff",
  },
  shadowBlur: 5,
});
const Loading = () => <TopBarProgress />;
export default Loading;
