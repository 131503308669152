import React from "react";
import {
  BrowserRouter,
  Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
// import { cssHash } from "css-hash";
// import generateTailwindCss from "tailwind-css-generator";
import Layout from "components/Layout";
import LoginPage from "containers/Auth/Loadable";
import PublicHomePage from "containers/Public/Home/Loadable";
import PublicAboutPage from "containers/Public/About/Loadable";
import VisiMisi from "containers/Public/VisinMisi/Loadable";
import Sejarah from "containers/Public/Sejarah/Loadable";
import Blog from "containers/Public/Blog/Loadable";
import Event from "containers/Public/Event/Loadable";
import Detail from "containers/Public/Detail/Loadable";
import DetailArtikel from "containers/Public/DetailArtikel/Loadable";
import NotFound from "containers/404";
import Guru from "containers/Public/Guru/Loadable";
import DetailGuru from "containers/Public/DetailGuru/Loadable";
import DownloadMateri from "containers/Public/DownloadMateri/Loadable";
import { createBrowserHistory } from "history";
import { QueryClient, QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Hydrate } from "react-query/hydration";
import KelasOnline from "containers/Public/KelasOnline/Loadable";
import JadwalSiswa from "containers/Public/JadwalSiswa/Loadable";
import Kehadiran from "containers/Public/Kehadiran/Loadable";
import Raport from "containers/Public/Raport/Loadable";
import Prestasi from "containers/Public/Prestasi";
import DetailPrestasi from "containers/Public/DetailPrestasi";
import Extrakulikuler from "containers/Public/Extrakulikuler";
import DetailExtrakulikuler from "containers/Public/DetailExtrakulikuler";
import Fasilitas from "containers/Public/Fasilitas";

const queryClient = new QueryClient();
const history = createBrowserHistory();
// const cssString = generateTailwindCss();

// cssHash(() => cssString);

function App(props) {
  const token = localStorage.getItem("token");
  return (
    <React.Fragment>
      <QueryClientProvider client={queryClient}>
        <BrowserRouter>
          <Router history={history} basename={process.env.PUBLIC_URL}>
            <Switch>
              {token && (
                <Redirect
                  exact
                  from={`${process.env.PUBLIC_URL}/login`}
                  to={`${process.env.PUBLIC_URL}/dashboard/home`}
                />
              )}
              <Route
                exact
                path={`${process.env.PUBLIC_URL}/`}
                component={PublicHomePage}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/profil/:jenjang`}
                component={PublicAboutPage}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/visi-misi/:jenjang`}
                component={VisiMisi}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/sejarah/:jenjang`}
                component={Sejarah}
              />
              <Route path={`${process.env.PUBLIC_URL}/guru`} component={Guru} />
              <Route
                path={`${process.env.PUBLIC_URL}/detail-guru/:slug`}
                component={DetailGuru}
              />
              <Route path={`${process.env.PUBLIC_URL}/blog/:jenjang`} component={Blog} />
              <Route
                path={`${process.env.PUBLIC_URL}/event/:jenjang`}
                component={Event}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/prestasi/:jenjang`}
                component={Prestasi}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/extrakulikuler/:jenjang`}
                component={Extrakulikuler}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/fasilitas`}
                component={Fasilitas}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/detail/:slug`}
                component={Detail}
              />
               <Route
                path={`${process.env.PUBLIC_URL}/detail-prestasi/:slug`}
                component={DetailPrestasi}
              />
               <Route
                path={`${process.env.PUBLIC_URL}/detail-extrakulikuler/:slug`}
                component={DetailExtrakulikuler}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/detail-artikel/:slug`}
                component={DetailArtikel}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/download-materi`}
                component={DownloadMateri}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/kelas-online`}
                component={KelasOnline}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/jadwal-siswa`}
                component={JadwalSiswa}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/kehadiran-siswa`}
                component={Kehadiran}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/raport-siswa`}
                component={Raport}
              />

              <Route
                path={`${process.env.PUBLIC_URL}/dashboard`}
                component={Layout}
              />
              <Route
                path={`${process.env.PUBLIC_URL}/login`}
                component={LoginPage}
              />
              <Route path="*" component={NotFound} />
            </Switch>
          </Router>
        </BrowserRouter>
        {/* <ReactQueryDevtools /> */}
      </QueryClientProvider>
    </React.Fragment>
  );
}

export default App;
