import { useRouteMatch, withRouter } from "react-router-dom";
import HomePage from "containers/Home/Loadable";
import DirectoryPTK from "containers/DirectoryPTK/Loadable";
import Layout from "components/AdminLTE";
import React from "react";
import DirektorySiswa from "containers/DirektorySiswa/Loadable";
import RekapKelas from "containers/RekapKelas/Loadable";
import LaporanKerusakan from "containers/LaporanKerusakan/Loadable";
import DataAbsensiHarian from "containers/DataAbsensiHarian/Loadable";
import Jenjang from "containers/Jenjang/Loadable";
import Kelas from "containers/Kelas/Loadable";
import DataMpel from "containers/DataMapel/Loadable";
import Sejarah from "containers/Halaman/Sejarah/Loadable";
import Profile from "containers/Halaman/Profile/Loadable";
import VisiMisi from "containers/Halaman/VisiMisi/Loadable";
import DataSlider from "containers/DataSlider/Loadable";
import DataEvent from "containers/DataEvent/Loadable";
import DataArtikel from "containers/DataArtikel/Loadable";
import DataPengurus from "containers/DataPengurus/Loadable";
import DataSarana from "containers/DataSarana/Loadable";
import KelasOnline from "containers/KelasOnline/Loadable";
import UpdateProfile from "containers/Profile/Loadable";
import Jadwal from "containers/Jadwal/Loadable";
import JadwalMengajar from "containers/JadwalMengajar/Loadable";
import UploadMateri from "containers/UploadMateri/Loadable";
import DetailPTK from "containers/DirectoryPTK/components/detail";
import DetailSiswa from "containers/DirektorySiswa/components/detail";
import DetailKelas from "containers/RekapKelas/components/detail";
import { submit } from "components/utils/proxy";
import RekapAbsensi from "containers/RekapAbsensi/Loadable";
import RekapNilai from "containers/RekapPenilaian/Loadable";
import InputNilai from "containers/InputNilai/Loadable";
import PengaturanKelas from "containers/PengaturanKelas/Loadable";
import DataAdmKepalaSekolah from "containers/DataAdmKepalaSekolah/Loadable";
import DataAdmGuru from "containers/DataAdmGuru/Loadable";
import DataAdmWaka from "containers/DataAdmWaka/Loadable";
import DataKTSP from "containers/DataKTSP/Loadable";
import DataSKP from "containers/DataSKP/Loadable";
import Alumni from "containers/Alumni/Loadable";
import DetailAlumni from "containers/Alumni/components/detail";
import Gedung from "containers/Gedung/Loadable";
import Unit from "containers/Unit/Loadable";
import JenisPelanggaran from "containers/JenisPelanggaran/Loadable";
import Sanksi from "containers/Sanksi/Loadable";
import DataPelanggaran from "containers/DataPelanggaran/Loadable";
import Catatan from "containers/Catatan/Loadable";
import CatatanGuru from "containers/CatatanGuru/Loadable";
import DataPrestasi from "containers/DataPrestasi";
import DataExtrakulikuler from "containers/DataExtrakulikuler";
import DataFasilitas from "containers/DataFasilitas";

const Layouts = (props) => {
  const { history } = props;
  let match = useRouteMatch();
  const [componentDidUpdate, setComponentDidUpdate] = React.useState(true);
  const token = localStorage.getItem("token");
  const user = JSON.parse(localStorage.getItem("user"));

  const getUserData = () => {
    try {
      submit("POST", "api/app/getUserInfo").then(async (res) => {
        if (res.roleName !== user?.roleName) {
          localStorage.clear();
        } else {
          user.mail = res.externalInfo.email;
          user.phone = res.externalInfo.telp_admin;
          user.foto_admin = res.externalInfo.foto_admin;
          let data = await submit(
            "POST",
            `api/page/unit/getByName?unit_jenjang=${res.externalInfo.jenjang}`
          );
          user.unit = {
            nama_unit: data.data.nama_unit,
            nss: data.data.nss,
            npsn: data.data.nisn,
            alamat: data.data.alamat_unit,
            no_tlp: data.data.no_tlp,
            jenjang_unit: data.data.jenjang_unit,
          };
          localStorage.removeItem("user");
          localStorage.setItem("user", JSON.stringify(user));
          if (componentDidUpdate) {
            setComponentDidUpdate(!componentDidUpdate);
          }
        }
      });
    } catch (e) {
      console.log(e);
    }
  };

  React.useEffect(() => {
    if (!token) {
      history.push(`${process.env.PUBLIC_URL}/login`);
    } else {
      getUserData();
    }
  }, [history, token]);

  return (
    <Layout>
      <HomePage path={`${match.url}/home`} />
      <DirectoryPTK path={`${match.url}/directory-ptk`} />
      <DetailPTK path={`${match.url}/detail-ptk/:slug`} />
      <UploadMateri path={`${match.url}/upload-materi`} />
      <RekapAbsensi path={`${match.url}/rekap-absensi`} />
      <RekapNilai path={`${match.url}/rekap-nilai`} />
      <DirektorySiswa path={`${match.url}/direktory-siswa`} />
      <DetailSiswa path={`${match.url}/detail-siswa/:slug`} />
      <DetailAlumni path={`${match.url}/detail-alumni/:slug`} />
      <LaporanKerusakan path={`${match.url}/laporan-kerusakan`} />
      <JadwalMengajar path={`${match.url}/data-jadwal-mengajar`} />
      <RekapKelas path={`${match.url}/rekap-kelas`} />
      <DetailKelas path={`${match.url}/detail-kelas/:id`} />
      <PengaturanKelas path={`${match.url}/pengaturan-kelas`} />
      <DataAbsensiHarian path={`${match.url}/data-absensi-harian`} />
      <InputNilai path={`${match.url}/data-penilaian-siswa`} />
      <DataAdmKepalaSekolah path={`${match.url}/data-adm-kepsek`} />
      <DataAdmGuru path={`${match.url}/data-adm-guru`} />
      <DataAdmWaka path={`${match.url}/data-adm-waka`} />
      <DataKTSP path={`${match.url}/data-ktsp`} />
      <DataSKP path={`${match.url}/data-skp`} />
      <Jenjang path={`${match.url}/data-jenjang`} />
      <Kelas path={`${match.url}/data-kelas`} />
      <DataMpel path={`${match.url}/data-mapel`} />
      <Profile path={`${match.url}/halaman-profil`} />
      <VisiMisi path={`${match.url}/halaman-visi`} />
      <Sejarah path={`${match.url}/halaman-sejarah`} />
      <DataSlider path={`${match.url}/data-slider`} />
      <DataEvent path={`${match.url}/data-event`} />
      <DataArtikel path={`${match.url}/data-artikel`} />
      <DataFasilitas path={`${match.url}/data-fasilitas`} />
      <DataPrestasi path={`${match.url}/data-prestasi`} />
      <DataExtrakulikuler path={`${match.url}/data-extrakulikuler`} />
      <DataPengurus path={`${match.url}/data-pengurus-website`} />
      <DataSarana path={`${match.url}/data-sarana-prasarana`} />
      <KelasOnline path={`${match.url}/kelas-online`} />
      <UpdateProfile path={`${match.url}/profile`} />
      <Jadwal path={`${match.url}/data-jadwal`} />
      <Gedung path={`${match.url}/data-gedung`} />
      <Unit path={`${match.url}/data-unit`} />
      <Alumni path={`${match.url}/data-alumni`} />
      <JenisPelanggaran path={`${match.url}/data-jenis-pelanggaran`} />
      <Sanksi path={`${match.url}/data-sanksi`} />
      <DataPelanggaran path={`${match.url}/data-pelanggaran`} />
      <Catatan path={`${match.url}/catatan/:slug`} />
      <CatatanGuru path={`${match.url}/catatan-guru/:slug`} />
    </Layout>
  );
};

export default withRouter(Layouts);
